import React, {useState} from "react";
import localStyles from "../finance-line-item/FinanceLineItem.module.scss";
import {Collapsible} from "../collapsible/Collapsible";
import {useSelector} from "react-redux";
import {selectUnitData} from "../../../store/unit/unitSlice";
import {validateInputFormatAmounts} from "../../../utils/general-utils/GeneralUtils";

type Props = {
  value: string;
  title: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, item?: any) => void;
};

export const FinanceLineItemBreakdown: React.FunctionComponent<Props> = ({value, title, onChange}): JSX.Element => {
  const unitData = useSelector(selectUnitData);
  const [breakdownOpen, setBreakdownOpen] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputsValidated = validateInputFormatAmounts(event?.target.value);
    if (inputsValidated && onChange) {
      onChange(event);
    }
  };

  const handleClickBreakdown = () => {
    setBreakdownOpen(!breakdownOpen);
  };

  return (
    <React.Fragment>
      <span className={localStyles.title}>{title}</span>
      <div className={localStyles.lineItemsContainer}>
        <span className={localStyles.lineItemTitle}>{title} Total</span>
        <input
          className={localStyles.lineItemValue}
          type="text"
          value={value || ""}
          placeholder={`0.00 ${unitData?.currency?.code ?? "USD"}`}
          onChange={handleInputChange}
        />
      </div>
      <div className={localStyles.breakdownSection}>
        <Collapsible open={false} title="View Nightly Breakdown" onClick={handleClickBreakdown} onChange={onChange} />
      </div>
    </React.Fragment>
  );
};
