export const ROUTE_SUFFIX = Object.freeze({
    CANCELLATION_WORKFLOW: "cancellation-workflow",
    CANCELLATION_SCHEDULE: "cancellation-schedule",
    CANCELLATION_EMAIL: "cancellation-email",
    ADD_ONS: "add-ons",
    ADD_NIGHTS: "add-nights",
    ADJUST_RENT: "adjust-rent",
    ADJUST_FEES: "adjust-fees",
    ADJUST_TAXES: "adjust-taxes",
    RESET_FINANCES: "reset-finances",
    APPEASEMENTS: "appeasements",
});

export const ERROR_MESSAGES = Object.freeze({
    NO_ROUTES: "No routes configured for this path. Please check all the routes for this app.",
});

/* This variable is used to determinate the number of nights displayed in the reset finance, specifically
 * in the "View and Adjust Nightly Breakdown" section
 */
export const NIGHT_RENT_LIMIT = 10;

//This value is the limit sum of rent + fees + taxes
export const FINANCE_LIMIT_SUM = "999.999,99";

/**
 * These are the reasons allowed to be displayed en the dropdown for Reset Finances [MIME-2925]
 * These reasons will be filtered by the slug attribute in the Reservation Service response
 */
export const REASONS_ALLOWED = [
    "FORCED_MOVE",
    "THIRD_PARTY_CORRECTION",
    "WHOLESALE_RESERVATION_RATES",
    "GROUP_SALES",
    "OWNER_STATEMENT_ACCOUNTING",
    "THIRD_PARTY_ALTERATION",
    "END_OF_MONTH_ACCOUNTING",
    "INHERITED_RESERVATION",
    "EXTENDED_STAY_PROGRAM",
];

export const CHANNEL_UNIT_ID_TYPE = "CHANNEL-UNIT";
export const VACASA_SOURCE_NAME = "VACASA";
