import React from "react";
import styles from "./ReservationDetailsItem.module.scss";

type Props = {
  title: string;
  value: number | string;
  url?: string;
  isUrl?: boolean;
};

export const ReservationDetailsItem: React.FunctionComponent<Props> = ({title, value, isUrl, url}): JSX.Element => {
  return (
    <div>
      <span className={styles.title}>{title}</span>
      <span className={styles.itemValue}>
        {isUrl ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        ) : (
          value
        )}
      </span>
    </div>
  );
};
