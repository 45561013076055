import React, {useEffect, useState} from "react";
import localStyles from "./VrmsInput.module.scss";
import {CircularProgress} from "@material-ui/core";

type Props = {
  title: string;
  regex?: RegExp;
  regexReplace?: RegExp;
  disabled?: boolean;
  maxLength?: number;
  loading?: boolean;
  prefix?: string;
  sufix?: string;
  placeholder?: string;
  value?: string;
  onChange?: (newValue: string) => void;
};

export const VrmsInput: React.FunctionComponent<Props> = ({
  title,
  prefix,
  disabled,
  maxLength,
  loading,
  regex,
  regexReplace,
  sufix,
  placeholder,
  value,
  onChange,
}): JSX.Element => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleSingleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let input = event.target.value.trim();

    // cut if maxLength
    if (maxLength) {
      input = input.slice(0, maxLength);
    }

    // without regex
    if (regex === undefined || regex === null) {
      onChange(input);
      return;
    }

    // regex check
    if (regex.test(input)) {
      if (onChange) {
        onChange(input);
      }
    } else {
      const noUnsupportedChars = input.replace(new RegExp(`[^${regex.source}]`, "g"), "").replace(regexReplace, "$1");
      onChange(noUnsupportedChars);
    }
  };

  return (
    <>
      <div className={localStyles.lineItemsContainer}>
        <span className={localStyles.lineItemTitle}>{title}</span>
        <div className={disabled ? localStyles.lineItemBoxDisabled : localStyles.lineItemBox}>
          {prefix && <span className={localStyles.lineItemSufix}>{prefix}</span>}
          <input
            className={localStyles.lineItemValue}
            disabled={disabled}
            type="text"
            value={inputValue ? inputValue : ""}
            placeholder={placeholder}
            onChange={(event) => handleSingleInputChange(event)}
          />
          {sufix && <span className={localStyles.lineItemSufix}>{sufix}</span>}
          {loading === true && (
            <div className={localStyles.lineItemSufix}>
              <CircularProgress color="inherit" size={20} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
