import React, {useEffect, useState} from "react";
import {NIGHT_RENT_LIMIT} from "../constants/constants";

export const useCollapsibleStyles = ({isOpen, dataLength, ref}) => {
  const heightValue = dataLength === 1 ? 65 : 53;
  const maxHeight = dataLength >= NIGHT_RENT_LIMIT ? 530 : dataLength * heightValue;
  const minOpacity = 0;
  const maxOpacity = 1;

  const [styles, setStyles] = useState<React.CSSProperties>({
    height: isOpen ? undefined : 0,
    maxHeight: isOpen ? undefined : 0,
    overflow: "",
    position: "relative",
  });

  useEffect(() => {
    if (isOpen) {
      setStyles({
        ...styles,
        maxHeight,
        height: ref.current?.getBoundingClientRect().height,
      });

      if (dataLength > NIGHT_RENT_LIMIT) {
        setStyles({
          ...styles,
          maxHeight,
          height: ref.current?.getBoundingClientRect().height,
          overflow: "scroll",
        });

        const list = document.getElementById("collapsibleContent");
        const overlay = document.getElementById("overlay") as any;

        if (list) {
          list.addEventListener("scroll", () => {
            const scrollTop = list.scrollTop;
            const innerHeight = list.clientHeight;
            const scrollHeight = list.scrollHeight;

            if (scrollTop + innerHeight >= scrollHeight) {
              overlay.style.opacity = minOpacity;
            } else if (scrollTop <= 0) {
              overlay.style.opacity = maxOpacity;
            } else {
              overlay.style.opacity = minOpacity;
            }
          });
        }
      } else {
        setStyles({
          ...styles,
          maxHeight,
          height: maxHeight,
        });
      }
    } else {
      setStyles({
        ...styles,
        height: 0,
      });
    }
  }, [isOpen]);

  return styles;
};
