import React, {useEffect, useState} from "react";
import {AdjustContainer} from "../shared/adjust-container/AdjustContainer";
import {useSelector} from "react-redux";
import {selectReservationFinance, selectReservationId} from "../../store/reservation/reservationSlice";
import {getFormattedDate} from "../../utils/dates-management/DatesManagement";
import {Rent} from "@common/typing";
import {useAppDispatch} from "../../store/store";
import {createPreview, selectNewAmounts, setNewAmounts} from "../../store/reservation-adjustments/reservationAdjustmentsSlice";
import {getRentLineItemsFormatted} from "../../utils/finance/Finance";

const Rows = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const reservationFinance = useSelector(selectReservationFinance);
  const currencyCode = reservationFinance?.attributes?.booked_currency || "USD";
  const newAmounts = useSelector(selectNewAmounts);
  const [inputValues, setInputValues] = useState<string[]>(newAmounts ?? []);

  const handleInputChange = (index: number, value: string) => {
    // This REGEX allow to enter only numbers, dots and two decimals
    const regex = /^\d+(\.\d{0,2})*?$/;

    if (regex.test(value) || value === "") {
      setInputValues((prevInputValues) => {
        const updatedValues = [...prevInputValues];
        updatedValues[index] = value;
        return updatedValues;
      });
    }
  };

  useEffect(() => {
    dispatch(setNewAmounts(inputValues));
  }, [inputValues, dispatch]);

  return (
    <>
      {reservationFinance?.attributes?.rent?.map((rent: Rent, index: number) => (
        <tr key={index}>
          <td>{getFormattedDate(rent.date, "dddd, MMM DD, YYYY")}</td>
          <td className="text-right">
            ${rent.rent} {currencyCode}
          </td>
          <td className="text-right">
            <input
              type="text"
              className="input-column"
              placeholder={`0.00 ${currencyCode}`}
              value={inputValues[index] || ""}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
          </td>
        </tr>
      ))}
    </>
  );
};

const Columns = (): JSX.Element => {
  return (
    <>
      <th>Date</th>
      <th className="text-right">Amount</th>
      <th className="title-right">Correct Amount</th>
    </>
  );
};

export const AdjustRent = React.memo(() => {
  const dispatch = useAppDispatch();
  const headerTitle = "Edit Reservation: Adjust Rent";
  const reservationId = useSelector(selectReservationId);
  const reservationFinance = useSelector(selectReservationFinance);
  const newAmounts = useSelector(selectNewAmounts);
  const reservationRent = reservationFinance?.attributes?.rent;

  const generatePreview = () => {
    const lineItemsFormatted = getRentLineItemsFormatted(reservationRent, newAmounts);
    dispatch(
      createPreview({
        reservationId,
        adjustmentType: "adjust_rent",
        lineItems: {
          rent: lineItemsFormatted,
        },
      })
    );
  };

  return <AdjustContainer columns={<Columns />} generatePreview={generatePreview} title={headerTitle} rows={<Rows />} />;
});
