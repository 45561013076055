import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {FrontendConfiguration} from "@common/configuration";
import {datadogLogs} from "@datadog/browser-logs";

export const Monitoring = {
    init: () => {
        // If loaded, we remove the reference to force refreshed cache
        sessionStorage.removeItem("cache-force-refreshed");

        // Sentry
        Sentry.init({
            dsn: FrontendConfiguration.getSentryDsn(),
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 0.01,
        });

        // Datadog Logger
        if (FrontendConfiguration.environment() === "local") return;
        datadogLogs.init({
            clientToken: FrontendConfiguration.getDataDogToken(),
            site: FrontendConfiguration.dataDogUrl(),
            forwardErrorsToLogs: true,
            env: FrontendConfiguration.datadogGlobalContext().env,
            sampleRate: 100,
        });
        datadogLogs.setLoggerGlobalContext(FrontendConfiguration.datadogCustomGlobalContext(FrontendConfiguration.DD_RESERVATION_MANAGEMENT_UI));
    },
};
