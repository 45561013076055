import {FinanceAdjustmentPreviewPayload, ReservationAdjustmentOverrideReason, Rent, Tax, Fee} from "@common/typing";
import {OverrideReasonFormatted} from "../../types/Types";
import {CreatePreviewData} from "../../store/reservation-adjustments/reservationAdjustmentsSlice";
import {getUserInfo} from "../user-info/UserInfo";
import {ROUTE_SUFFIX, REASONS_ALLOWED} from "../../constants/constants";

/**
 * Filter the reservation adjustment override reasons to display the actives and the allowed reasons
 * @param reasons ReservationAdjustmentOverrideReason
 * @returns OverrideReasonFiltered []
 */
export const filterOverrideReasons = (reasons: ReservationAdjustmentOverrideReason[]): ReservationAdjustmentOverrideReason[] => {
    return reasons?.filter((reasonFiltered) => reasonFiltered?.attributes?.is_active && REASONS_ALLOWED.includes(reasonFiltered?.attributes?.slug));
};

/**
 * Format the reservation adjustment override reasons to be displayed in the reasons dropdown
 * @param reasons ReservationAdjustmentOverrideReason
 * @returns OverrideReasonFormatted []
 */
export const formatOverrideReasons = (reasons: ReservationAdjustmentOverrideReason[]): OverrideReasonFormatted[] => {
    return reasons?.map((reasonFiltered) => ({
        label: reasonFiltered?.attributes?.name,
        value: reasonFiltered?.attributes?.slug,
    }));
};

export const getAdjustmentPreviewPayload = (data: CreatePreviewData): FinanceAdjustmentPreviewPayload => {
    const {email: userEmail} = getUserInfo();
    const adjustmentPreviewPayload: FinanceAdjustmentPreviewPayload = {
        data: {
            type: "adjustment_preview",
            attributes: {
                adjusted_by_email: userEmail,
                finances: {
                    line_items: data?.lineItems,
                    ...(data?.adjustmentType === getAdjustmentTypeFormatted(ROUTE_SUFFIX.RESET_FINANCES) && {override_note: data?.overrideNote}),
                    ...(data?.adjustmentType === getAdjustmentTypeFormatted(ROUTE_SUFFIX.RESET_FINANCES) && {override_reason: data?.overrideReason}),
                    ...(data?.adjustmentType === getAdjustmentTypeFormatted(ROUTE_SUFFIX.RESET_FINANCES) && {booked_currency: data?.bookedCurrency}),
                },
            },
        },
    };

    return adjustmentPreviewPayload;
};

export const getAdjustmentTypeFormatted = (adjustmentType: string) => {
    return adjustmentType.split("-").join("_");
};

export const validateInputFormatAmounts = (value: string) => {
    // This REGEX allow to enter only numbers, dots and two decimals
    const regex = /^\d+(\.\d{0,2})*?$/;
    return regex.test(value) || value === "";
};

export const orderToOriginalRent = (originalRent: Rent[]): Rent[] => {
    return [...originalRent].sort((rent, nextRent) => rent.date.localeCompare(nextRent.date));
};

export const orderToOriginal = (originalArray: (Fee | Tax)[], disorderedArray: (Fee | Tax)[]): (Fee | Tax)[] => {
    // Create a mapping of ids to their original indexes
    const idToIndexMap = {};
    originalArray.forEach((obj, index) => {
        idToIndexMap[obj.id] = index;
    });

    // Sort the disordered array based on the order of ids in the original array
    const orderedArray = [...disorderedArray].sort((a, b) => {
        const indexA = idToIndexMap[a.id];
        const indexB = idToIndexMap[b.id];
        return indexA - indexB;
    });

    return orderedArray;
};
