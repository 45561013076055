import React, {ChangeEvent, useEffect, useCallback} from "react";
import {Grid, TextField, makeStyles, CircularProgress} from "@material-ui/core";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import styles from "./SearchBar.module.scss";
import * as _ from "lodash";
import {IdpService} from "@common/services";

interface ISearchBarProps {
  value: string;
  placeholder: string;
  title: string;
  onSearchChange: (event: any) => void;
}

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 35,
      height: "30px",
      color: "#2c3439;",
      fontFamily: "Nunito Sans, sans-serif;",
      fontWeight: "300 !important",
      background: `transparent
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23707579' width='20' height='20' viewBox='0 0 16 16' %3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' %3E%3C/path%3E%3C/svg>")
      no-repeat 5px`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "transparent",
    },
  },
  root: {
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
      marginBottom: "0px",
    },
    "& .MuiFormControl-fullWidth ": {
      width: "630px",
      border: "1px solid #e5ebed",
      borderRadius: "8px",
      height: "52px",
    },
  },
}));

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: any) => option.email + option.name,
});

export const SearchBar: React.FunctionComponent<ISearchBarProps> = ({placeholder, title, value, onSearchChange}) => {
  //Local State
  const idpService = IdpService.getInstance();
  const classes = useStyles();
  const [options, setOptions] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const [canSearch, setCanSearch] = React.useState(true);

  useEffect(() => {
    if (value === "") {
      setOpen(false);
      setOptions([]);
    }

    if (value !== "" && value.length > 2 && canSearch) {
      getIdpUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!open) setOptions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getIdpUsers = async () => {
    try {
      setSearching(true);
      const emailFilter = "vacasa.com";
      const {data} = await idpService.getIdpUsers(value.concat(",", emailFilter));
      const idpUsersMapped = data?.map((user: any) => {
        return {
          id: user.id,
          name: `${user.attributes.given_name} ${user.attributes.family_name}`,
          email: user.attributes.email,
        };
      });
      setOptions(idpUsersMapped);
    } catch (error) {
      console.error(error);
    } finally {
      setSearching(false);
    }
  };

  const onSearchInputChange = useCallback(
    _.debounce((value: any) => {
      onSearchChange(value.toLowerCase().split(" ").join("."));
    }, 700),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSelectOption = (newInputValue: any) => {
    setCanSearch(!canSearch);
  };

  return (
    <div className={styles.searchBarContainer}>
      <span className={styles.title}>{title}</span>
      <Autocomplete
        className={styles.selectOptions}
        id="select-users"
        classes={classes}
        options={options}
        getOptionLabel={(x) => {
          return !x ? "" : `${x.email}`;
        }}
        getOptionSelected={(option, value) => option.id === value.id}
        filterOptions={filterOptions}
        open={open}
        noOptionsText=""
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event: ChangeEvent<{}>, newValue: any) => {
          handleSelectOption(newValue);
          setOptions(newValue ? [newValue] : options);
        }}
        onInputChange={(event: ChangeEvent<{}>, newInputValue: string) => {
          onSearchInputChange(newInputValue);
        }}
        renderInput={(params) => (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                {...params}
                id="users-results"
                fullWidth
                margin="normal"
                placeholder={placeholder}
                variant="outlined"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {searching ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}
        renderOption={(option) => {
          return <div>{option.email}</div>;
        }}
      />
    </div>
  );
};
