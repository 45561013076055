import React from "react";
import {HeaderLight} from "../shared/header-light/HeaderLight";
import {Appeasements} from "./Appeasements";
import {AppeasementsPreview} from "./AppeasementsPreview";
import {useSelector} from "react-redux";
import {selectPreviewFinances} from "../../store/reservation-adjustments/reservationAdjustmentsSlice";
import {ReservationErrorModal} from "../shared/modals/Modals";

export const AppeasementsContainer: React.FunctionComponent = () => {
  const appeasementPreview = useSelector(selectPreviewFinances);
  const headerTitle = `Appeasements${appeasementPreview ? " Preview" : ""}`;

  return (
    <>
      <HeaderLight title={headerTitle} />
      {appeasementPreview ? <AppeasementsPreview /> : <Appeasements />}
      {/* If there is an error calling any service we display a modal with the error details */ <ReservationErrorModal />}
    </>
  );
};
