import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ReservationIncludedType, ReservationDataType, ReservationType, ReservationAdjustmentOverrideReason, Tax, Fee, Rent} from "@common/typing";
import {EventService, ReservationImplicitService} from "@common/services";
import {EventSources, EventTypes} from "@common/utils";
import {datadogLogs} from "@datadog/browser-logs";
import isEmpty from "lodash/isEmpty";
import {RootState} from "../store";

// Service singletons
const reservationService = ReservationImplicitService.getInstance();

export const fetchReservation = createAsyncThunk("reservation/fetchReservation", async (id: number): Promise<ReservationDataType> => {
    try {
        const reservation = await reservationService.getReservation(id, false);

        if (isEmpty(reservation?.data)) throw new Error("Reservation not found. Please make sure the reservation exists.");

        return await reservationService.getReservation(reservation?.data[0]?.id, true, "reservation_finance,source,addon_menu");
    } catch (error) {
        EventService.dispatch(datadogLogs, {
            title: `[VRMS] Preview Fail`,
            message: `[VRMS] Preview Fail - ResID: ${id}`,
            type: EventTypes.VRMS_FETCH_RESERVATION_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {legacy_reservation_id: id, response: error},
        });
        throw new Error(
            `Error fetching the reservation. Please reload the page or try again later. If this message persists, please contact support. ${error}`
        );
    }
});

export const fetchOverrideReasons = createAsyncThunk("reservation/fetchOverrideReasons", async (): Promise<ReservationAdjustmentOverrideReason[]> => {
    try {
        return await reservationService.getReservationAdjustmentOverrideReasons();
    } catch (error) {
        throw new Error("Error fetching the reservation adjustment override reasons. Please reload the page or try again later.");
    }
});

export interface ReservationState {
    data: ReservationType;
    overrideReasons: ReservationAdjustmentOverrideReason[];
    included: ReservationIncludedType[];
    overrideReasonSelected: string;
    originalLineItems: {
        reservationRent: {
            total: string;
            rent: Rent[];
        };
        reservationFees: {
            total: string;
            fees: Fee[];
        };
        reservationTaxes: {
            total: string;
            taxes: Tax[];
        };
    };
    updatedLineItems: {
        reservationRent: {
            total: string;
            rent: Rent[];
        };
        reservationFees: {
            total: string;
            fees: Fee[];
        };
        reservationTaxes: {
            total: string;
            taxes: Tax[];
        };
    };
    actions: {
        isFetching: boolean;
    };
    error: {
        hasError: boolean;
        errorMessage: string;
    };
}

const initialState: ReservationState = {
    data: null,
    overrideReasons: null,
    included: null,
    overrideReasonSelected: "0",
    originalLineItems: {
        reservationRent: {
            total: null,
            rent: null,
        },
        reservationFees: {
            total: null,
            fees: null,
        },
        reservationTaxes: {
            total: null,
            taxes: null,
        },
    },
    updatedLineItems: {
        reservationRent: {
            total: null,
            rent: null,
        },
        reservationFees: {
            total: null,
            fees: null,
        },
        reservationTaxes: {
            total: null,
            taxes: null,
        },
    },
    actions: {
        isFetching: true,
    },
    error: {
        hasError: false,
        errorMessage: "",
    },
};

const reservationSlice = createSlice({
    name: "reservation",
    initialState,
    reducers: {
        setTotalRent: (state, {payload}) => {
            state.updatedLineItems.reservationRent.total = payload;
        },
        setNewRent: (state, {payload}) => {
            state.updatedLineItems.reservationRent.rent = payload;
        },
        setNewFees: (state, {payload}) => {
            state.updatedLineItems.reservationFees.fees = state.updatedLineItems.reservationFees.fees.map((obj) =>
                obj.id === payload.id ? {...obj, ...payload} : obj
            );
        },
        setTotalTaxes: (state, {payload}) => {
            state.updatedLineItems.reservationTaxes.total = payload;
        },
        setNewTaxes: (state, {payload}) => {
            state.updatedLineItems.reservationTaxes.taxes = payload;
        },
        resetLineItems: (state) => {
            state.updatedLineItems = {...state.originalLineItems};
            state.overrideReasonSelected = "0";
        },
        setOverrideReason: (state, {payload}) => {
            state.overrideReasonSelected = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReservation.fulfilled, (state, {payload}: any) => {
                const reservationFinance = payload?.included?.filter((include) => include.type === "reservation_finance")[0];
                state.actions.isFetching = false;
                state.data = payload?.data;
                state.included = payload?.included;
                state.originalLineItems.reservationTaxes.total = reservationFinance?.attributes?.taxes
                    ?.reduce((total: number, currentValue: Tax) => total + parseFloat(currentValue.amount), 0)
                    .toFixed(2);
                state.originalLineItems.reservationTaxes.taxes = reservationFinance?.attributes?.taxes;
                state.originalLineItems.reservationFees.total = reservationFinance?.attributes?.fees
                    ?.reduce((total: number, currentValue: Fee) => total + parseFloat(currentValue.amount), 0)
                    .toFixed(2);
                state.originalLineItems.reservationFees.fees = reservationFinance?.attributes?.fees;
                state.originalLineItems.reservationRent.total = reservationFinance?.attributes?.rent
                    ?.reduce((total: number, currentValue: Rent) => total + parseFloat(currentValue.rent), 0)
                    .toFixed(2);
                state.originalLineItems.reservationRent.rent = reservationFinance?.attributes?.rent;
                state.updatedLineItems = {...state.originalLineItems};
            })
            .addCase(fetchReservation.rejected, (state, {error}) => {
                state.actions.isFetching = false;
                state.error.hasError = true;
                state.error.errorMessage = error?.message;
            })
            .addCase(fetchOverrideReasons.fulfilled, (state, {payload}) => {
                state.overrideReasons = payload;
            })
            .addCase(fetchOverrideReasons.rejected, (state, {error}) => {
                state.error.hasError = true;
                state.error.errorMessage = error?.message;
            });
    },
});

export const selectIsFetching = (state: RootState) => state.reservation.actions.isFetching;
export const selectReservationData = (state: RootState) => state.reservation?.data;
export const selectReservationId = (state: RootState) => state.reservation?.data?.id;
export const selectReservationErrorMessage = (state: RootState) => state.reservation.error.errorMessage;
export const selectHasReservationError = (state: RootState) => state.reservation.error.hasError;
export const selectOverrideReasons = (state: RootState) => state.reservation.overrideReasons;
export const selectTotalTaxes = (state: RootState) => state.reservation.updatedLineItems.reservationTaxes.total;
export const selectReservationTaxes = (state: RootState) => state.reservation.updatedLineItems.reservationTaxes.taxes;
export const selectTotalFees = (state: RootState) => state.reservation.updatedLineItems.reservationFees.total;
export const selectReservationFees = (state: RootState) => state.reservation.updatedLineItems.reservationFees.fees;
export const selectTotalRent = (state: RootState) => state.reservation.updatedLineItems.reservationRent.total;
export const selectOriginalTotalRent = (state: RootState) => state.reservation.originalLineItems.reservationRent.total;
export const selectOriginalRent = (state: RootState) => state.reservation.originalLineItems.reservationRent.rent;
export const selectOriginalTaxes = (state: RootState) => state.reservation.originalLineItems.reservationTaxes.taxes;
export const selectOriginalTotalTaxes = (state: RootState) => state.reservation.originalLineItems.reservationTaxes.total;
export const selectOriginalFees = (state: RootState) => state.reservation.originalLineItems.reservationFees.fees;
export const selectReservationRent = (state: RootState) => state.reservation.updatedLineItems.reservationRent.rent;
export const selectOverrideReason = (state: RootState) => state.reservation.overrideReasonSelected;
export const selectReservationFinance = (state: RootState) =>
    state.reservation?.included?.filter((include) => include.type === "reservation_finance")[0];
export const selectReservationSource = (state: RootState) => state.reservation?.included?.filter((include) => include.type === "source")[0];
export const selectReservationAddons = (state: RootState) => state.reservation?.included?.filter((include) => include.type === "addon_menu")[0];

// Reducer Actions
export const {setTotalRent, setTotalTaxes, resetLineItems, setNewRent, setNewFees, setNewTaxes, setOverrideReason} = reservationSlice.actions;

export default reservationSlice.reducer;
