import React from "react";
import {HeaderLight} from "../shared/header-light/HeaderLight";
import {ResetFinances} from "./ResetFinances";
import {ResetFinancesPreview} from "./ResetFinancesPreview";
import {useSelector} from "react-redux";
import {selectPreviewFinances} from "../../store/reservation-adjustments/reservationAdjustmentsSlice";
import {ReservationErrorModal} from "../shared/modals/Modals";

export const ResetFinancesContainer: React.FunctionComponent = () => {
  const resetFinancePreview = useSelector(selectPreviewFinances);
  const headerTitle = `Reset Finances${resetFinancePreview ? " Preview" : ""}`;

  return (
    <>
      <HeaderLight title={headerTitle} />
      {resetFinancePreview ? <ResetFinancesPreview /> : <ResetFinances />}
      {/* If there is an error calling any service we display a modal with the error details */ <ReservationErrorModal />}
    </>
  );
};
