import {configureStore} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import ReservationReducer from "./reservation/reservationSlice";
import ReservationAdjustmentsReducer from "./reservation-adjustments/reservationAdjustmentsSlice";
import UnitReducer from "./unit/unitSlice";
import QuotesReducer from "./quotes/quotesSlice";
import FinanceRegisterReducer from "./finance-register/financeRegisterSlice";

export const store = configureStore({
    reducer: {
        reservation: ReservationReducer,
        reservationAdjustments: ReservationAdjustmentsReducer,
        unit: UnitReducer,
        quotes: QuotesReducer,
        financeRegister: FinanceRegisterReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
