import React, {useLayoutEffect} from "react";
import localStyles from "./HeaderLight.module.scss";
import {BackButton} from "../back-button/BackButton";
import {useAppDispatch} from "../../../store/store";
import {useParams} from "react-router-dom";
import {
  fetchOverrideReasons,
  fetchReservation,
  selectIsFetching,
  selectReservationData,
  selectReservationFees,
  selectReservationSource,
} from "../../../store/reservation/reservationSlice";
import {useSelector} from "react-redux";
import {LegacyUrlGenerator} from "@common/utils";
import {getFormattedDate} from "../../../utils/dates-management/DatesManagement";
import {fetchConnectUnit, fetchUnit, fetchUnitFees, selectUnitData} from "../../../store/unit/unitSlice";
import {HeaderLightItemLoader} from "../content-loaders/ContentLoaders";

type HeaderLightProps = {
  title: string;
};

type HeaderLightItemProps = {
  title: string;
  value: number | string;
  url?: string;
  isUrl?: boolean;
};

const ReservationDetailsLight: React.FunctionComponent<HeaderLightProps> = ({title}): JSX.Element => {
  const dispatch = useAppDispatch();
  const {id: reservationId} = useParams<{id: string}>();
  const isFetchingReservation = useSelector(selectIsFetching);
  const reservationData = useSelector(selectReservationData);
  const reservationSource = useSelector(selectReservationSource);
  const reservationSourceName = reservationSource?.attributes?.name;
  const reservationFees = useSelector(selectReservationFees);
  const unitData = useSelector(selectUnitData);

  useLayoutEffect(() => {
    const fetchReservationData = async () => {
      await dispatch(fetchReservation(parseInt(reservationId)))?.unwrap();
      await dispatch(fetchOverrideReasons())?.unwrap();
    };
    fetchReservationData();
  }, [dispatch]);

  useLayoutEffect(() => {
    const fetchUnitData = async () => {
      await dispatch(fetchUnit(reservationData?.attributes?.legacy_unit_id))?.unwrap();
      await dispatch(fetchConnectUnit(reservationData?.attributes?.legacy_unit_id))?.unwrap();
      await dispatch(fetchUnitFees(reservationFees.map((fee) => fee.id).join(",")))?.unwrap();
    };
    if (reservationData) {
      fetchUnitData();
    }
  }, [reservationData, dispatch]);

  const HeaderLightItem: React.FunctionComponent<HeaderLightItemProps> = ({title, value, isUrl, url}): JSX.Element => {
    return (
      <>
        <span className={localStyles.headerItemTitle}>{title}</span>
        <span className={localStyles.headerItemValue}>
          {isUrl ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          ) : (
            value
          )}
        </span>
      </>
    );
  };

  return (
    <div className={localStyles.headerContainer}>
      <span className={localStyles.headerTitle}>{title}</span>
      <div className={localStyles.headerItemsContainer}>
        {
          /* If the reservation data is being fetched a content loader is displayed */
          isFetchingReservation || !reservationData || !unitData ? (
            <HeaderLightItemLoader />
          ) : (
            <>
              <HeaderLightItem title="Created on" value={getFormattedDate(reservationData?.attributes?.created_at, "dddd, MMM DD, YYYY")} />
              <HeaderLightItem title="Source" value={reservationSourceName} />
              <HeaderLightItem
                title="Unit Code"
                value={unitData?.unit_code}
                isUrl
                url={LegacyUrlGenerator.toAdminUnit(reservationData?.attributes?.legacy_unit_id)}
              />
              <HeaderLightItem title="Res ID" value={reservationId} isUrl url={LegacyUrlGenerator.toReservation(reservationId)} />
              <HeaderLightItem title="Check In" value={getFormattedDate(reservationData?.attributes?.first_night, "dddd, MMM DD, YYYY")} />
            </>
          )
        }
      </div>
    </div>
  );
};

export const HeaderLight: React.FunctionComponent<HeaderLightProps> = ({title}): JSX.Element => {
  return (
    <>
      <BackButton text="Back" />
      <ReservationDetailsLight title={title} />
    </>
  );
};
