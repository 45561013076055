import React, {useLayoutEffect} from "react";
import localStyles from "./Header.module.scss";
import {BackButton} from "../back-button/BackButton";
import {ReservationDetailsItem} from "../reservation-details-item/ReservationDetailsItem";
import {ReservationDetailsLoader} from "../content-loaders/ContentLoaders";
import {useAppDispatch} from "../../../store/store";
import {useParams} from "react-router-dom";
import {
  fetchOverrideReasons,
  fetchReservation,
  selectIsFetching,
  selectReservationData,
  selectReservationFees,
} from "../../../store/reservation/reservationSlice";
import {useSelector} from "react-redux";
import {LegacyUrlGenerator} from "@common/utils";
import {dayjs, getFormattedDate} from "../../../utils/dates-management/DatesManagement";
import {fetchConnectUnit, fetchUnit, fetchUnitFees} from "../../../store/unit/unitSlice";

type HeaderProps = {
  title: string;
};

const ReservationDetails: React.FunctionComponent<HeaderProps> = ({title}): JSX.Element => {
  const dispatch = useAppDispatch();
  const {id: reservationId} = useParams<{id: string}>();
  const isFetchingReservation = useSelector(selectIsFetching);
  const reservationData = useSelector(selectReservationData);
  const reservationFees = useSelector(selectReservationFees);

  useLayoutEffect(() => {
    const fetchReservationData = async () => {
      await dispatch(fetchReservation(parseInt(reservationId)))?.unwrap();
      await dispatch(fetchOverrideReasons())?.unwrap();
    };
    fetchReservationData();
  }, [dispatch]);

  useLayoutEffect(() => {
    const fetchUnitData = async () => {
      await dispatch(fetchUnit(reservationData?.attributes?.legacy_unit_id))?.unwrap();
      await dispatch(fetchConnectUnit(reservationData?.attributes?.legacy_unit_id))?.unwrap();
      await dispatch(fetchUnitFees(reservationFees.map((fee) => fee.id).join(",")))?.unwrap();
    };
    if (reservationData) {
      fetchUnitData();
    }
  }, [reservationData, dispatch]);

  return (
    <>
      <h1 className={localStyles.headerTitle}>{title}</h1>
      <div className="panel-container-flex-header">
        {isFetchingReservation || !reservationData ? (
          <ReservationDetailsLoader />
        ) : (
          <>
            <div className={localStyles.leftPanel}>
              <ReservationDetailsItem title="Reservation" value={reservationId} isUrl url={LegacyUrlGenerator.toReservation(reservationId)} />
              <ReservationDetailsItem
                title="Unit"
                value={reservationData?.attributes?.legacy_unit_id}
                isUrl
                url={LegacyUrlGenerator.toAdminUnit(reservationData?.attributes?.legacy_unit_id)}
              />
              <ReservationDetailsItem title="Created" value={getFormattedDate(reservationData?.attributes?.created_at, "dddd, MMM DD, YYYY")} />
            </div>
            <div className={localStyles.rightPanel}>
              <ReservationDetailsItem title="First Night" value={getFormattedDate(reservationData?.attributes?.first_night, "dddd, MMM DD, YYYY")} />
              <ReservationDetailsItem title="Last Night" value={getFormattedDate(reservationData?.attributes?.last_night, "dddd, MMM DD, YYYY")} />
              <ReservationDetailsItem
                title="Check Out"
                value={getFormattedDate(dayjs(reservationData?.attributes?.last_night).add(1, "day").toISOString(), "dddd, MMM DD, YYYY")}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const Header: React.FunctionComponent<HeaderProps> = ({title}): JSX.Element => {
  return (
    <>
      <BackButton text="Back" />
      <ReservationDetails title={title} />
    </>
  );
};
