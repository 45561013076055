import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {EventService, ReservationImplicitService} from "@common/services";
import {EventSources, VrmsEventTypes} from "@common/utils";
import {datadogLogs} from "@datadog/browser-logs";
import {RootState} from "../store";
import {
    AdjustmentPersistGenericResponse,
    AdjustmentPreviewGenericResponse,
    Fee,
    FinanceAdjustmentPersistPayload,
    FinanceAdjustmentPreviewPayload,
    OriginalFinanceItem,
    PreviewFinanceItem,
    Rent,
    Tax,
} from "@common/typing";
import {FrontendConfiguration} from "@common/configuration";
import {getAdjustmentPreviewPayload} from "../../utils/general-utils/GeneralUtils";
import {getUserInfo} from "../../utils/user-info/UserInfo";

// Service singletons
const reservationService = ReservationImplicitService.getInstance();

export const createPreview = createAsyncThunk(
    "reservationAdjustments/createPreview",
    async (data: CreatePreviewData, {getState}): Promise<AdjustmentPreviewGenericResponse> => {
        const state: any = getState();
        const {reservationId, adjustmentType} = data;
        const legacyReservationId = state?.reservation?.data?.attributes?.legacy_reservation_id;
        const adjustmentDataPayload: FinanceAdjustmentPreviewPayload = getAdjustmentPreviewPayload(data);

        try {
            const response = await reservationService.createFinanceAdjustmentPreview({
                reservationId: reservationId,
                payload: adjustmentDataPayload,
                adjustmentType,
            });
            EventService.dispatch(datadogLogs, {
                title: `[VRMS-${adjustmentType}] preview success`,
                message: `[VRMS-${adjustmentType}] preview success. ResID: ${reservationId}`,
                type: VrmsEventTypes[`${adjustmentType}_preview_success`],
                source: EventSources.UI,
                level: EventService.INFO_LEVEL,
                data: {legacy_reservation_id: legacyReservationId, response, bodyData: adjustmentDataPayload},
            });
            return response;
        } catch (error) {
            EventService.dispatch(datadogLogs, {
                title: `[VRMS-${adjustmentType}] preview failed`,
                message: `[VRMS-${adjustmentType}] preview failed. ResID: ${reservationId}`,
                type: VrmsEventTypes[`${adjustmentType}_preview_fail`],
                source: EventSources.UI,
                level: EventService.ERROR_LEVEL,
                data: {legacy_reservation_id: legacyReservationId, response: error},
            });
            throw new Error(
                `Error while creating the adjustment preview. Please reload the page or try again later. If this message persists, please contact support. Status Code: ${error?.status}`
            );
        }
    }
);

export const persistPreview = createAsyncThunk(
    "reservationAdjustments/persistPreview",
    async (adjustmentType: string, {getState}): Promise<AdjustmentPersistGenericResponse> => {
        const state: any = getState();
        const legacyReservationId = state?.reservation?.data?.attributes?.legacy_reservation_id;
        const reservationId = state?.reservation?.data?.id;
        const {email: userEmail} = getUserInfo();
        const adjustmentPreviewId = state?.reservationAdjustments?.adjustmentPreviewId;
        const adjustmentPersistDataPayload: FinanceAdjustmentPersistPayload = {
            data: {
                type: adjustmentType,
                attributes: {
                    client: FrontendConfiguration.getVacasaClient(),
                    adjusted_by_email: userEmail,
                    finances: {
                        type: "ADJUSTMENT_PREVIEW",
                        adjustment_preview_id: adjustmentPreviewId,
                    },
                },
            },
        };

        try {
            const response = await reservationService.persistFinanceAdjustmentPreview({
                reservationId,
                payload: adjustmentPersistDataPayload,
                adjustmentType,
            });
            EventService.dispatch(datadogLogs, {
                title: `[VRMS-${adjustmentType}] persist success`,
                message: `[VRMS-${adjustmentType}] persist success. ResID: ${reservationId}`,
                type: VrmsEventTypes[`${adjustmentType}_persist_success`],
                source: EventSources.UI,
                level: EventService.INFO_LEVEL,
                data: {legacy_reservation_id: legacyReservationId, response, bodyData: adjustmentPersistDataPayload},
            });
            return response;
        } catch (error) {
            EventService.dispatch(datadogLogs, {
                title: `[VRMS-${adjustmentType}] persist failed`,
                message: `[VRMS-${adjustmentType}] persist failed. ResID: ${legacyReservationId}`,
                type: VrmsEventTypes[`${adjustmentType}_persist_fail`],
                source: EventSources.UI,
                level: EventService.ERROR_LEVEL,
                data: {legacy_reservation_id: legacyReservationId, response: error},
            });
            throw new Error(
                `Error while persisting the adjustment preview. Please reload the page or try again later. If this message persists, please contact support. Status Code: ${error?.status}`
            );
        }
    }
);

export interface CreatePreviewData {
    reservationId: string;
    adjustmentType: string;
    lineItems: {
        [key: string]: (Fee | Rent | Tax)[];
    };
    overrideNote?: string;
    overrideReason?: string;
    bookedCurrency?: string;
}
export interface ReservationAdjustmentsState {
    originalFinances: OriginalFinanceItem;
    previewFinances: PreviewFinanceItem;
    newAmounts: string[];
    adjustmentPreviewId: string;
    actions: {
        isLoading: boolean;
        isSaving: boolean;
        saved: boolean;
    };
    error: {
        hasError: boolean;
        errorMessage: string;
    };
}

export const initialState: ReservationAdjustmentsState = {
    originalFinances: null,
    previewFinances: null,
    newAmounts: [],
    adjustmentPreviewId: null,
    actions: {
        isLoading: false,
        isSaving: false,
        saved: false,
    },
    error: {
        hasError: false,
        errorMessage: "",
    },
};

const reservationAdjustmentsSlice = createSlice({
    name: "reservationAdjustments",
    initialState,
    reducers: {
        resetFinancesPreview: (state) => {
            state.originalFinances = null;
            state.previewFinances = null;
            state.adjustmentPreviewId = null;
        },
        setNewAmounts: (state, {payload}) => {
            state.newAmounts = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createPreview.pending, (state) => {
                state.actions.isLoading = true;
            })
            .addCase(createPreview.rejected, (state, payload) => {
                state.actions.isLoading = false;
                state.error.hasError = true;
                state.error.errorMessage = payload?.error?.message;
            })
            .addCase(createPreview.fulfilled, (state, {payload}: any) => {
                state.actions.isLoading = false;
                state.originalFinances = payload?.data?.attributes?.original_finances;
                state.previewFinances = payload?.data?.attributes?.preview_finances;
                state.adjustmentPreviewId = payload?.data?.id;
            })
            .addCase(persistPreview.pending, (state) => {
                state.actions.isSaving = true;
            })
            .addCase(persistPreview.rejected, (state, payload) => {
                state.actions.saved = false;
                state.actions.isSaving = false;
                state.error.hasError = true;
                state.error.errorMessage = payload?.error?.message;
            })
            .addCase(persistPreview.fulfilled, (state) => {
                state.actions.saved = true;
                state.actions.isSaving = false;
            });
    },
});

export const selectIsLoadingPreview = (state: RootState) => state.reservationAdjustments?.actions.isLoading;
export const selectIsSaving = (state: RootState) => state.reservationAdjustments?.actions.isSaving;
export const selectIsSaved = (state: RootState) => state.reservationAdjustments?.actions.saved;
export const selectHasReservationAdjustmentError = (state: RootState) => state.reservationAdjustments?.error?.hasError;
export const selectAdjustmentErrorMessage = (state: RootState) => state.reservationAdjustments?.error?.errorMessage;
export const selectOriginalFinances = (state: RootState) => state.reservationAdjustments?.originalFinances;
export const selectPreviewFinances = (state: RootState) => state.reservationAdjustments?.previewFinances;
export const selectNewAmounts = (state: RootState) => state.reservationAdjustments?.newAmounts;
export const selectAdjustmentPreviewId = (state: RootState) => state.reservationAdjustments?.adjustmentPreviewId;

// Reducer Actions
export const {resetFinancesPreview, setNewAmounts} = reservationAdjustmentsSlice.actions;

export default reservationAdjustmentsSlice.reducer;
