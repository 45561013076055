import React from "react";
import {BrowserRouter as Router, useRoutes} from "react-router-dom";
import {APP_ROUTES} from "./routes/Routes";

// Import Libraries
import {ImplicitFlow, ThemeProvider} from "@vacasa/react-components-lib";
import defaultTheme from "@vacasa/react-components-lib/src/themes/default";

// Redux
import {store} from "./store/store";
import {Provider} from "react-redux";

// Config
import {getRouteIdpConfig, hasIdpConfigEmptyValues} from "./config/idp.config";
import {FrontendConfiguration} from "@common/configuration";

import {ErrorComponent} from "./components/shared/error-component/ErrorComponent";
import {ERROR_MESSAGES} from "./constants/constants";
import "./index.scss";

// Mapping all the routes
const AppRoutes = () => {
  return useRoutes(APP_ROUTES);
};

export const App = () => {
  // IDP Configuration
  const routeIdpConfig = getRouteIdpConfig();
  const implicitIdpConfig = FrontendConfiguration.implicitFlowConfig();

  // If IDP config object has empty values we return an error component
  if (hasIdpConfigEmptyValues(routeIdpConfig)) {
    return <ErrorComponent message={ERROR_MESSAGES.NO_ROUTES} code={404} />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <ImplicitFlow
        env={implicitIdpConfig.env}
        clientId={implicitIdpConfig.clientId}
        redirectUrl={implicitIdpConfig.redirectUrl + routeIdpConfig.callback} // this is required so we have custom callback url that persist the route based config
        scopes={routeIdpConfig.scopes}
        storage="sessionStorage"
        audience={routeIdpConfig.audience[process.env.REACT_APP_ENV]}
        tokenRefresh={routeIdpConfig.tokenRefresh}
      >
        <Provider store={store}>
          <div className="app">
            <Router>
              <AppRoutes />
            </Router>
          </div>
        </Provider>
      </ImplicitFlow>
    </ThemeProvider>
  );
};
