import {ROUTE_SUFFIX} from "../constants/constants";

const tokenRefresh = true;

const callbackUrl = (suffix: string): string => {
    return `/callback/${suffix}`;
};

export interface idpConfigType {
    scopes: string;
    audience: {
        dev: string;
        stage: string;
        prod: string;
    };
    callback?: string;
    tokenRefresh: boolean;
}

export const idpConfig: {[key: string]: idpConfigType} = {
    default: {
        scopes: "",
        audience: {
            dev: "",
            stage: "",
            prod: "",
        },
        callback: "",
        tokenRefresh,
    },
};

idpConfig[ROUTE_SUFFIX.CANCELLATION_SCHEDULE] = {
    scopes: "reservations:read",
    audience: {
        dev: "reservation.vacasadev.io",
        stage: "reservation.vacasastage.io",
        prod: "reservation.vacasa.io",
    },
    callback: callbackUrl(ROUTE_SUFFIX.CANCELLATION_SCHEDULE),
    tokenRefresh: false,
};

idpConfig[ROUTE_SUFFIX.CANCELLATION_WORKFLOW] = {
    scopes: "reservations:read reservations:admin units:read payments:read reservations:write notifications:write payments:write users:read",
    audience: {
        dev: "resman.platform-tools.vacasadev.io stage.accounts.vacasa.io reservation.vacasadev.io stage.units.vacasa.io",
        stage: "resman.platform-tools.vacasastage.io stage.accounts.vacasa.io reservation.vacasastage.io stage.units.vacasa.io",
        prod: "resman.platform-tools.vacasa.io accounts.vacasa.io reservation.vacasa.io units.vacasa.io",
    },
    callback: callbackUrl(ROUTE_SUFFIX.CANCELLATION_WORKFLOW),
    tokenRefresh,
};

idpConfig[ROUTE_SUFFIX.CANCELLATION_EMAIL] = {
    scopes: "reservations:read units:read payments:read notifications:write",
    audience: {
        dev: "resman.platform-tools.vacasadev.io reservation.vacasadev.io stage.units.vacasa.io",
        stage: "resman.platform-tools.vacasastage.io reservation.vacasastage.io stage.units.vacasa.io",
        prod: "resman.platform-tools.vacasa.io reservation.vacasa.io units.vacasa.io",
    },
    callback: callbackUrl(ROUTE_SUFFIX.CANCELLATION_EMAIL),
    tokenRefresh,
};

idpConfig[ROUTE_SUFFIX.ADD_ONS] = {
    scopes: "reservations:read reservations:admin availability:read",
    audience: {
        dev: "reservation.vacasadev.io",
        stage: "reservation.vacasastage.io",
        prod: "reservation.vacasa.io",
    },
    callback: callbackUrl(ROUTE_SUFFIX.ADD_ONS),
    tokenRefresh,
};

idpConfig[ROUTE_SUFFIX.ADD_NIGHTS] = {
    scopes: "reservations:read reservations:admin",
    audience: {
        dev: "dev.units.vacasa.io reservation.vacasadev.io availability.vacasadev.io",
        stage: "stage.units.vacasa.io reservation.vacasastage.io availability.vacasastage.io",
        prod: "units.vacasa.io reservation.vacasa.io availability.vacasa.io",
    },
    callback: callbackUrl(ROUTE_SUFFIX.ADD_NIGHTS),
    tokenRefresh,
};

idpConfig[ROUTE_SUFFIX.ADJUST_RENT] = {
    scopes: "reservations:read units:read reservations:write",
    audience: {
        dev: "dev.units.vacasa.io reservation.vacasadev.io reservation.vacasastage.io stage.units.vacasa.io",
        stage: "stage.units.vacasa.io reservation.vacasastage.io",
        prod: "units.vacasa.io reservation.vacasa.io",
    },
    callback: callbackUrl(ROUTE_SUFFIX.ADJUST_RENT),
    tokenRefresh,
};

idpConfig[ROUTE_SUFFIX.ADJUST_FEES] = {
    scopes: "reservations:read units:read reservations:write",
    audience: {
        dev: "dev.units.vacasa.io reservation.vacasadev.io reservation.vacasastage.io stage.units.vacasa.io",
        stage: "stage.units.vacasa.io reservation.vacasastage.io",
        prod: "units.vacasa.io reservation.vacasa.io",
    },
    callback: callbackUrl(ROUTE_SUFFIX.ADJUST_FEES),
    tokenRefresh,
};

idpConfig[ROUTE_SUFFIX.ADJUST_TAXES] = {
    scopes: "reservations:read units:read reservations:write",
    audience: {
        dev: "dev.units.vacasa.io reservation.vacasadev.io reservation.vacasastage.io stage.units.vacasa.io",
        stage: "stage.units.vacasa.io reservation.vacasastage.io",
        prod: "units.vacasa.io reservation.vacasa.io",
    },
    callback: callbackUrl(ROUTE_SUFFIX.ADJUST_TAXES),
    tokenRefresh,
};

idpConfig[ROUTE_SUFFIX.RESET_FINANCES] = {
    scopes: "reservations:read units:read reservations:write reservations:admin fees:read quotes:read quotes:write",
    audience: {
        dev: "reservation.vacasastage.io stage.units.vacasa.io resman.platform-tools.vacasastage.io",
        stage: "stage.units.vacasa.io reservation.vacasastage.io resman.platform-tools.vacasastage.io",
        prod: "units.vacasa.io reservation.vacasa.io resman.platform-tools.vacasa.io",
    },
    callback: callbackUrl(ROUTE_SUFFIX.RESET_FINANCES),
    tokenRefresh,
};

idpConfig[ROUTE_SUFFIX.APPEASEMENTS] = {
    scopes: "reservations:read units:read reservations:write reservations:admin fees:read quotes:read quotes:write finance-register:read finance-register:write users:read",
    audience: {
        dev: "reservation.vacasastage.io stage.units.vacasa.io stage.accounts.vacasa.io resman.platform-tools.vacasastage.io finance-register-service.vacasastage.io",
        stage: "stage.units.vacasa.io reservation.vacasastage.io stage.accounts.vacasa.io resman.platform-tools.vacasastage.io finance-register-service.vacasastage.io",
        prod: "units.vacasa.io reservation.vacasa.io resman.platform-tools.vacasa.io accounts.vacasa.io finance-register-service.vacasa.io",
    },
    callback: callbackUrl(ROUTE_SUFFIX.APPEASEMENTS),
    tokenRefresh,
};

/**
 * Obtains Scopes and Audiences based on the accessed Route
 */
export function getRouteIdpConfig(): idpConfigType {
    const url = window.location.href;
    const idpConfigKeys = Object.keys(idpConfig);

    for (const key of idpConfigKeys) {
        if (url.includes(key)) {
            return idpConfig[key];
        }
    }

    return idpConfig["default"];
}

/**
 * Checks if some of the values of the idpConfig object are empty
 * @param idpConfig this is the object which contains all the information of the routes (scopes, audiences, callback)
 */
export function hasIdpConfigEmptyValues(idpConfig: idpConfigType): boolean {
    // Get all the values from idpConfig object
    const idpConfigValues = Object.values(idpConfig);

    // Check if some of these values are empty
    return idpConfigValues.some((value) => value === "");
}
