import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ResManService, ReservationImplicitService} from "@common/services";
import isEmpty from "lodash/isEmpty";

// Service singletons
const resmanService = ResManService.getInstance();
const reservationService = ReservationImplicitService.getInstance();

export const createTransactionDetail = createAsyncThunk("financeRegister/createTransactionDetail", async (data: any): Promise<any> => {
    const {
        transaction_type,
        category_type,
        amount,
        currency,
        ext_reference_type,
        ext_reference_id,
        service,
        created_by,
        responsible_party,
        approved_by,
    } = data;

    const queryParams = {
        data: {
            type: "TransactionDetail",
            attributes: {
                transaction_type,
                category_type,
                amount,
                currency,
                ext_reference_type,
                ext_reference_id,
                service,
                created_by,
                responsible_party,
                approved_by,
            },
        },
    };

    try {
        return await resmanService.createTransactionDetail(queryParams);
    } catch (error) {
        throw new Error(error?.message);
    }
});

export const getUserIdByEmail = createAsyncThunk("connect/getUserIdByEmail", async (email: string): Promise<any> => {
    try {
        const data = await resmanService.getUserIdByEmail(email);
        return data.id;
    } catch (error) {
        throw new Error(`User not available. ${error}`);
    }
});

export interface financeRegisterState {
    isFetching: boolean;
    data: any;
}

export const initialState: financeRegisterState = {
    isFetching: false,
    data: null,
};

const financeRegisterSlice = createSlice({
    name: "financeRegister",
    initialState,
    reducers: {
        resetForm: (state, {payload}) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createTransactionDetail.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(createTransactionDetail.fulfilled, (state, {payload}) => {
                state.isFetching = false;
                state.data = payload;
            });
    },
});

// Reducer Actions
export const {resetForm} = financeRegisterSlice.actions;

export default financeRegisterSlice.reducer;
