import React from "react";
import localStyles from "./ResetFinances.module.scss";
import {Button} from "@vacasa/react-components-lib";
import {useAppDispatch} from "../../store/store";
import {
  persistPreview,
  resetFinancesPreview,
  selectIsSaved,
  selectIsSaving,
  selectOriginalFinances,
  selectPreviewFinances,
} from "../../store/reservation-adjustments/reservationAdjustmentsSlice";
import {useSelector} from "react-redux";
import {Spin, message} from "antd";
import {redirectToURL} from "../../utils/redirect-url/RedirectUrl";
import {LegacyUrlGenerator} from "@common/utils";
import {useParams} from "react-router-dom";
import {getFormattedDate} from "../../utils/dates-management/DatesManagement";

type ResetFinancePreviewItemProps = {
  title: string;
  rows: any[];
};

type PreviewItemRowProps = {
  text: string;
  beforeValue: string;
  afterValue?: string;
  hasDifference?: boolean;
  isRent?: boolean;
  day?: string;
};

const PreviewItemRow: React.FunctionComponent<PreviewItemRowProps> = ({text, beforeValue, afterValue, hasDifference, isRent, day}): JSX.Element => {
  const totalDifference = parseFloat(afterValue) - parseFloat(beforeValue);

  return (
    <div className={localStyles.itemRowContainer}>
      {isRent ? (
        <div className={localStyles.nightContainer}>
          <span className={localStyles.nightDayText}>{day}</span>
          <span className={localStyles.nightDateText}>{text}</span>
        </div>
      ) : (
        <div className={localStyles.itemRowText}>{text}</div>
      )}
      <div className={localStyles.itemRowValues}>{parseFloat(beforeValue).toFixed(2)}</div>
      <div className={localStyles.itemRowValues}>
        {parseFloat(afterValue).toFixed(2)}{" "}
        {hasDifference && (
          <span className={localStyles.difference}>
            ({totalDifference >= 0 ? "+" : "-"}${Math.abs(totalDifference).toFixed(2)})
          </span>
        )}
      </div>
    </div>
  );
};

const ResetFinancePreviewItem: React.FunctionComponent<ResetFinancePreviewItemProps> = ({title, rows}): JSX.Element => {
  return (
    <div className={localStyles.financeContainer}>
      <span className={localStyles.titlePreviewItem}>{title}</span>
      <div className={localStyles.headerContainer}>
        <div className={localStyles.headerItem} />
        <div className={localStyles.headerItem}>Before</div>
        <div className={localStyles.headerItem}>After</div>
      </div>
      {rows.map((row, index) => (
        <PreviewItemRow
          key={index}
          text={row.text}
          beforeValue={row.beforeValue}
          afterValue={row.afterValue}
          hasDifference={row.hasDifference}
          isRent={row.isRent}
          day={row.day}
        />
      ))}
    </div>
  );
};

export const ResetFinancesPreview = () => {
  const dispatch = useAppDispatch();
  const originalFinances = useSelector(selectOriginalFinances);
  const previewFinances = useSelector(selectPreviewFinances);
  const isSaved = useSelector(selectIsSaved);
  const isSaving = useSelector(selectIsSaving);
  const {id} = useParams<{id: string}>();

  const rent: PreviewItemRowProps[] = originalFinances?.rent?.map((item) => ({
    day: getFormattedDate(item.date, "dddd"),
    text: item.date,
    beforeValue: item.rent,
    afterValue: previewFinances?.rent?.filter((rent) => rent?.date === item?.date)[0]?.rent,
    isRent: true,
  }));

  const rentWithTotal = [
    ...rent,
    {
      text: "Total",
      beforeValue: originalFinances?.rent?.reduce((total, current) => total + parseFloat(current.rent), 0),
      afterValue: previewFinances?.rent?.reduce((total, current) => total + parseFloat(current.rent), 0),
      hasDifference: true,
    },
  ];

  const fees: PreviewItemRowProps[] = originalFinances?.fees?.map((item) => ({
    text: item.name,
    beforeValue: item.amount,
    afterValue: previewFinances?.fees?.filter((fee) => fee.id === item?.id)[0]?.amount,
  }));

  const feesWithTotal = [
    ...fees,
    {
      text: "Total",
      beforeValue: originalFinances?.fees?.reduce((total, current) => total + parseFloat(current.amount), 0),
      afterValue: previewFinances?.fees?.reduce((total, current) => total + parseFloat(current.amount), 0),
      hasDifference: true,
    },
  ];

  const taxes: PreviewItemRowProps[] = originalFinances?.taxes?.map((item) => ({
    text: item.name,
    beforeValue: item.amount,
    afterValue: previewFinances?.taxes?.filter((tax) => tax.id === item?.id)[0]?.amount,
  }));

  const taxesWithTotal = [
    ...taxes,
    {
      text: "Total",
      beforeValue: originalFinances?.taxes?.reduce((total, current) => total + parseFloat(current.amount), 0),
      afterValue: previewFinances?.taxes?.reduce((total, current) => total + parseFloat(current.amount), 0),
      hasDifference: true,
    },
  ];

  const handleClickEdit = () => {
    dispatch(resetFinancesPreview());
  };

  const handleClickSave = async () => {
    const timeToRedirect = 5;
    window.scrollTo(0, 0);
    await dispatch(persistPreview("reset_finances"))?.unwrap();
    message.success(`Finances were reset. You will be redirected to Admin Finances in ${timeToRedirect} seconds.`, timeToRedirect);
    redirectToURL(LegacyUrlGenerator.toReservationFinance(id), 5);
  };

  return (
    <>
      <div className={localStyles.resetFinancePanel}>
        {
          /* Display a message while the preview is being persisted */
          isSaving && (
            <div className={localStyles.savingChangesContainer}>
              <Spin />
              <span className={localStyles.savingChangesMessage}>We are saving the changes...please wait</span>
            </div>
          )
        }
        <div className={localStyles.itemsContainer}>
          <ResetFinancePreviewItem rows={rentWithTotal} title="Rent" />
          <ResetFinancePreviewItem rows={feesWithTotal} title="Fees" />
          <ResetFinancePreviewItem rows={taxesWithTotal} title="Taxes" />
        </div>
      </div>
      <div className={localStyles.buttonsSection}>
        <Button customClass={localStyles.undoChangesButton} disabled={isSaved || isSaving} variant="info" onClick={handleClickEdit}>
          Edit
        </Button>
        <Button customClass={localStyles.submitButton} disabled={isSaved || isSaving} variant="secondary" onClick={handleClickSave}>
          Save
        </Button>
      </div>
    </>
  );
};
