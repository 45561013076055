import React from "react";
import localStyles from "./Appeasements.module.scss";
import {selectIsSaving} from "../../store/reservation-adjustments/reservationAdjustmentsSlice";
import {useSelector} from "react-redux";
import {Spin} from "antd";

export const AppeasementsPreview = () => {
  const isSaving = useSelector(selectIsSaving);

  return (
    <>
      <div className={localStyles.appeasementPanel}>
        {
          /* Display a message while the preview is being persisted */
          isSaving && (
            <div className={localStyles.savingChangesContainer}>
              <Spin />
              <span className={localStyles.savingChangesMessage}>We are saving the changes...please wait</span>
            </div>
          )
        }
      </div>
    </>
  );
};
