import React, {useRef, useState} from "react";
import {Icon} from "@vacasa/react-components-lib";
import localStyles from "./Collapsible.module.scss";
import {useCollapsibleStyles} from "../../../hooks/useCollapsibleStyles";
import {NIGHT_RENT_LIMIT} from "../../../constants/constants";
import {getFormattedDate} from "../../../utils/dates-management/DatesManagement";
import {useSelector} from "react-redux";
import {selectReservationRent} from "../../../store/reservation/reservationSlice";
import {selectUnitData} from "../../../store/unit/unitSlice";

type Props = {
  open?: boolean;
  title: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, item?: any) => void;
};

type BreakdownData = {
  date: string;
  day: string;
};

type BreakdownItemsProps = {
  data: BreakdownData[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, item?: any, value?: string) => void;
};

export const BreakdownItems: React.FunctionComponent<BreakdownItemsProps> = ({data}): JSX.Element => {
  const updatedReservationRent = useSelector(selectReservationRent);
  const unitData = useSelector(selectUnitData);
  const rentFormatted = updatedReservationRent?.map((rent) => {
    return {
      date: rent.date,
      day: getFormattedDate(rent.date, "ddd").toUpperCase(),
    };
  });

  return (
    <div className={localStyles.breakdownItemsContainer}>
      {data?.map((night, index: number) => (
        <div className={localStyles.nightItem} key={index}>
          <div className={localStyles.nightContainer}>
            <span className={localStyles.nightDayText}>
              {night?.day} (NIGHT {index + 1})
            </span>
            <span className={localStyles.nightDateText}>{night?.date}</span>
          </div>
          <input
            className={localStyles.inputBreakdown}
            type="text"
            placeholder={`0.00 ${unitData?.currency?.code ?? "USD"}`}
            value={updatedReservationRent[index].rent || ""}
            disabled
          />
        </div>
      ))}
      {rentFormatted?.length > NIGHT_RENT_LIMIT && <div id="overlay" className={localStyles.overlay}></div>}
    </div>
  );
};

export const Collapsible: React.FunctionComponent<Props> = ({open, title, onClick, onChange}): JSX.Element => {
  const originalRent = useSelector(selectReservationRent);
  const rentFormatted = originalRent?.map((rent) => {
    return {
      date: rent.date,
      day: getFormattedDate(rent.date, "ddd").toUpperCase(),
    };
  });

  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const styles = useCollapsibleStyles({isOpen, dataLength: rentFormatted?.length, ref});

  const handleClickOpening = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick) onClick(e);
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div className={localStyles.collapsibleCard}>
        <>
          <div className={localStyles.collapsibleHeader}>
            <div className={localStyles.titleText} onClick={handleClickOpening}>
              {title}
            </div>
            <button type="button" className={localStyles.collapsibleIconButton} onClick={handleClickOpening}>
              {isOpen ? <Icon.ChevronUp height={15} width={15} /> : <Icon.ChevronDown height={15} width={15} />}
            </button>
          </div>
        </>
        <div id="collapsibleContent" className={localStyles.collapsibleContent} style={styles}>
          <div ref={ref}>
            <div className={localStyles.collapsibleContentPadding}>
              <BreakdownItems data={rentFormatted} onChange={onChange} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
