import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom/client";
import {App} from "./App";
import {Monitoring, setFavicon} from "./config";

//Initiate Sentry && Datadog
Monitoring.init();

//Set Favicon
setFavicon();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
