//@ts-ignore
import jwt_decode from "jwt-decode";

/**
 * Decodes the idToken from sessionStorage to get the user info
 * @returns string
 */
export const getUserInfo = (): any => {
    const token = sessionStorage.getItem("idToken");
    return token && jwt_decode(token);
};
