import React, {useState, useEffect} from "react";
import localStyles from "./Appeasements.module.scss";
import {PreviewLoader} from "../shared/content-loaders/ContentLoaders";
import {VrmsInput} from "../shared/vrms-input/VrmsInput";
import {useDropdown} from "../../hooks/useDropdown";
import {createTransactionDetail, getUserIdByEmail} from "../../store/finance-register/financeRegisterSlice";
import {fetchReservation} from "../../store/reservation/reservationSlice";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {Button} from "@vacasa/react-components-lib";
import {SearchBar} from "./search-bar/SearchBar";
import {AppeasementScenarios} from "./AppeasementScenarios";
import {redirectToURL} from "../../utils/redirect-url/RedirectUrl";
import {LegacyUrlGenerator} from "@common/utils";
import {message} from "antd";
import jwt from "jsonwebtoken";

export const Appeasements = React.memo(() => {
  const [currency, setCurrency] = useState<string>("");
  const dispatch = useDispatch();

  // reservation id
  const {id} = useParams<{id: string}>();

  // logged user id
  const token = sessionStorage.getItem("idToken");
  const decodedToken = jwt.decode(token);
  // @ts-ignore
  const userEmail = decodedToken.email;
  const [userId, setUserId] = useState<number | null>(null);

  const [errors, setErrors] = useState<string[]>([]);
  const addError = (error) => {
    if (!errors.includes(error)) {
      setErrors((prevErrors) => [...prevErrors, error]);
    }
  };
  const removeError = (error) => {
    if (errors.includes(error)) {
      setErrors((prevErrors) => prevErrors.filter((e) => e != error));
    }
  };

  useEffect(() => {
    if (userEmail.includes("@vacasa.com")) {
      const actionResultConnect = dispatch(getUserIdByEmail(userEmail) as any);
      actionResultConnect
        .then((result) => {
          setUserId(parseInt(result.payload));
          if (result.error) {
            setUserId(null);
          }
        })
        .catch(() => {
          setUserId(null);
        });
    }
  }, [userEmail, dispatch]);

  useEffect(() => {
    setLoading(true);
    const actionResultReservation = dispatch(fetchReservation(parseInt(id)) as any);
    actionResultReservation
      .then((result) => {
        if (result.payload && result.payload.included) {
          setCurrency(result.payload?.included[1].attributes?.booked_currency);
        }
        if (result.error) {
          setCurrency("USD");
        }
      })
      .catch(() => {
        setCurrency("USD");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, id]);

  const isLoadingPreview = false;
  const [creditTo, setCreditTo] = useState<string>("Guest");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUserData, setLoadingUserData] = useState<boolean>(false);
  const [formValidation, setFormValidation] = useState<boolean>(false);

  const [responsible, setResponsible] = useState<string>(
    AppeasementScenarios.find((e) => {
      return e.responsibleParty.name === "Vacasa";
    }).responsibleParty.name
  );

  const [Type, setType] = useState<string>(
    AppeasementScenarios.find((e) => {
      return e.responsibleParty.name === responsible;
    }).possibleReasons[0].name
  );

  const [amount, setAmount] = useState<string>("");
  const [approved, setApproved] = useState<string>("");
  const [approvedId, setApprovedId] = useState<number | null>(null);

  useEffect(() => {
    setApprovedId(null);

    if (approved.includes("@vacasa.com")) {
      const errorMessage = "Approved by: User id not found for the email entered";
      removeError(errorMessage);
      setLoadingUserData(true);
      const actionResultConnect = dispatch(getUserIdByEmail(approved) as any);
      actionResultConnect
        .then((result) => {
          setApprovedId(parseInt(result.payload));
          if (result.error) {
            addError(errorMessage);
            setApprovedId(null);
          }
        })
        .catch(() => {
          addError(errorMessage);
          setApprovedId(null);
        })
        .finally(() => {
          setLoadingUserData(false);
        });
    }
  }, [approved, dispatch]);

  useEffect(() => {
    if (
      AppeasementScenarios.find((e) => e.responsibleParty.name === responsible).possibleReasons.find((e) => e.name === Type)?.key &&
      amount &&
      parseInt(amount) !== 0 &&
      currency !== "" &&
      id &&
      userId &&
      AppeasementScenarios.find((e) => e.responsibleParty.name === responsible).responsibleParty?.key &&
      approvedId
    ) {
      setFormValidation(true);
    } else {
      setFormValidation(false);
    }
  }, [AppeasementScenarios, responsible, Type, amount, currency, id, userId, approvedId]);

  const ResponsibleDropdown = useDropdown({
    label: "Responsible For Appeasement*",
    options: AppeasementScenarios.map((e) => {
      return e.responsibleParty.name;
    }),
    placeholder: "Select an option",
    value: responsible,
    onChange: (event) => {
      setResponsible(event.target.value);
    },
  });

  let TypeDropdown = useDropdown({
    label: "Type*",
    options: AppeasementScenarios.find((e) => e.responsibleParty.name === responsible).possibleReasons.map((e) => e.name),
    placeholder: "Select an option",
    value: Type,
    onChange: (event) => {
      setType(event.target.value);
    },
  });

  useEffect(() => {
    setType(AppeasementScenarios.find((e) => e.responsibleParty.name === responsible).possibleReasons.map((e) => e.name)[0]);
    TypeDropdown = useDropdown({
      label: "Type*",
      options: AppeasementScenarios.find((e) => e.responsibleParty.name === responsible).possibleReasons.map((e) => e.name),
      placeholder: "Select an option",
      value: Type,
      onChange: (event) => {
        setType(event.target.value);
      },
    });
  }, [responsible]);

  const createAppeasementTransactionDetail = () => {
    setLoading(true);
    setErrors([]);

    // normalize amount to include cents
    // 10 => 10.00 or 10.1 => 10.10 / 10.111 not allowed by regex
    let submitAmount = amount;
    if (submitAmount.includes(".")) {
      const decimals = submitAmount.split(".")[1].length;
      switch (decimals) {
        case 0:
          submitAmount = submitAmount + "00";
          break;
        case 1:
          submitAmount = submitAmount + "0";
          break;
        default:
          break;
      }
    } else {
      submitAmount = submitAmount + ".00";
    }

    const actionResult = dispatch(
      createTransactionDetail({
        transaction_type: "guest",
        category_type: AppeasementScenarios.find((e) => e.responsibleParty.name === responsible).possibleReasons.find((e) => e.name === Type)?.key,
        amount: submitAmount.replace(".", ""),
        currency,
        ext_reference_type: "reservation",
        ext_reference_id: id,
        service: "admin",
        created_by: userId,
        responsible_party: AppeasementScenarios.find((e) => e.responsibleParty.name === responsible).responsibleParty?.key,
        approved_by: approvedId,
      }) as any
    );

    actionResult
      .then(() => {
        const timeToRedirect = 5;
        message.success(`Appeasement was created. You will be redirected to Admin in ${timeToRedirect} seconds.`, timeToRedirect);
        redirectToURL(LegacyUrlGenerator.toReservationFinance(id), timeToRedirect);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div>
        {!isLoadingPreview ? (
          <div>
            <div className={localStyles.appeasementPanel}>
              <VrmsInput
                title="Credit To*"
                disabled
                value={creditTo}
                onChange={(newValue) => {
                  setCreditTo(newValue);
                }}
              />
              {ResponsibleDropdown.dropdown}
              {TypeDropdown.dropdown}
              <VrmsInput
                title="Amount*"
                disabled={loading}
                loading={loading}
                regex={/^\d+\.\d{2}$/}
                regexReplace={/(\d+\.\d{2}).*/}
                maxLength={16}
                prefix="$"
                sufix={currency}
                placeholder="0.00"
                value={amount}
                onChange={(newValue) => {
                  setAmount(newValue);
                }}
              />
              <SearchBar value={approved} title="Approved By*" placeholder="Start typing..." onSearchChange={setApproved} />
              {errors.length != 0 && (
                <div className={localStyles.errorPanel}>
                  Error Log:
                  {errors.map((error) => {
                    return <ul>{error}</ul>;
                  })}
                </div>
              )}
            </div>
            <div className={localStyles.appeasementContainer}>
              <Button
                customClass={localStyles.submitButton}
                variant="secondary"
                disabled={loading || loadingUserData || !formValidation}
                onClick={createAppeasementTransactionDetail}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <PreviewLoader />
        )}
      </div>
    </>
  );
});
