import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EventService, ResManService, UnitImplicitService} from "@common/services";
import {EventSources, EventTypes} from "@common/utils";
import {ConnectUnit, ConnectUnitData, FeeItem, GetListResponse, UnitItemAttributes} from "@common/typing";
import {datadogLogs} from "@datadog/browser-logs";
import isEmpty from "lodash/isEmpty";
import {RootState} from "../store";

// Service singletons
const unitService = UnitImplicitService.getInstance();
const resmanService = ResManService.getInstance();

export const fetchUnit = createAsyncThunk("unit/fetchUnit", async (id: number): Promise<UnitItemAttributes> => {
    try {
        const unit = await unitService.getUnitByLegacyId(id);
        if (isEmpty(unit)) {
            throw new Error("Unit not found. Please make sure that the Legacy Unit ID exists.");
        }
        return unit;
    } catch (error) {
        EventService.dispatch(datadogLogs, {
            title: "[VRMS] Fetch Unit Fail",
            message: `Unit Legacy ID: ${id} `,
            type: EventTypes.VRMS_FETCH_UNIT_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {error},
        });

        throw new Error(error?.message);
    }
});

export const fetchConnectUnit = createAsyncThunk("unit/fetchConnectUnit", async (id: number): Promise<ConnectUnitData> => {
    try {
        return await resmanService.getConnectUnitById(id);
    } catch (error) {
        EventService.dispatch(datadogLogs, {
            title: "[VRMS] Fetch Connect Unit Fail",
            message: `Unit Legacy ID: ${id} `,
            type: EventTypes.VRMS_FETCH_UNIT_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {error},
        });

        throw new Error(error?.message);
    }
});

export const fetchUnitFees = createAsyncThunk("unit/fetchUnitFees", async (feeIds: string): Promise<GetListResponse<FeeItem>> => {
    try {
        if (feeIds === "") return;
        return await resmanService.getFees(feeIds);
    } catch (error) {
        throw new Error(error?.message);
    }
});

export interface unitState {
    isFetching: boolean;
    data: UnitItemAttributes;
    connectUnit: ConnectUnit;
    unitFees: FeeItem[];
}

const initialState: unitState = {
    isFetching: false,
    data: null,
    connectUnit: null,
    unitFees: null,
};

const unitSlice = createSlice({
    name: "unit",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUnit.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchUnit.fulfilled, (state, {payload}) => {
                state.isFetching = false;
                state.data = payload?.attributes;
            })
            .addCase(fetchUnit.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(fetchConnectUnit.fulfilled, (state, {payload}) => {
                state.isFetching = false;
                state.connectUnit = payload?.data;
            })
            .addCase(fetchConnectUnit.rejected, (state) => {
                state.isFetching = false;
            })
            .addCase(fetchUnitFees.fulfilled, (state, {payload}) => {
                state.unitFees = isEmpty(payload?.data) ? [] : (payload.data as any);
            });
    },
});

export const selectUnitData = (state: RootState) => state.unit?.data;
export const selectConnectUnitData = (state: RootState) => state.unit.connectUnit;
export const selectUnitFees = (state: RootState) => state.unit?.unitFees;

export default unitSlice.reducer;
