import React, {useState} from "react";
import {AdjustContainer} from "../shared/adjust-container/AdjustContainer";
import {AdjustPreview} from "../shared/adjust-preview/AdjustPreview";

//TODO: Remove fake data
const fakeTaxes = [
  {
    name: "Oregon State Tax",
    percentageRate: "1.5%",
    flatRate: "0.00 USD",
    amount: "35.44",
    correctAmount: "0.00 USD",
  },
  {
    name: "Gearhart City Tax",
    percentageRate: "7.0%",
    flatRate: "0.00 USD",
    amount: "165.43",
    correctAmount: "0.00 USD",
  },
  {
    name: "Clatsop County, OR TRTJOTP",
    percentageRate: "1.0%",
    flatRate: "0.00 USD",
    amount: "23.64",
    correctAmount: "0.00 USD",
  },
];

const Columns = (): JSX.Element => {
  return (
    <>
      <th>Tax District</th>
      <th>Percentage Rate</th>
      <th>Flat Rate</th>
      <th className="text-right">Amount</th>
      <th className="title-right">Correct Amount</th>
    </>
  );
};

const Rows = (): JSX.Element => {
  return (
    <>
      {fakeTaxes.map((tax, index) => (
        <tr key={index}>
          <td>{tax.name}</td>
          <td>{tax.percentageRate}</td>
          <td>{tax.flatRate}</td>
          <td className="text-right">{tax.amount} USD</td>
          <td className="text-right">
            <input className="input-column" placeholder={tax.correctAmount} type="text" />
          </td>
        </tr>
      ))}
      <tr>
        <td>Total</td>
        <td></td>
        <td></td>
        <td className="text-right">$224.51 USD</td>
      </tr>
    </>
  );
};

export const AdjustTaxes = () => {
  const headerTitle = "Edit Reservation: Adjust Taxes";
  const [preview, setPreview] = useState(null);

  const generatePreview = () => {
    //TODO: Generate Taxes Preview
    console.log("Generating taxes preview...");
    setPreview({
      originalFinance: [],
      previewFinance: [],
    });
  };

  return (
    <>
      {preview ? <AdjustPreview /> : <AdjustContainer columns={<Columns />} generatePreview={generatePreview} title={headerTitle} rows={<Rows />} />}
    </>
  );
};
