import {FrontendConfiguration} from "@common/configuration";

const environment = FrontendConfiguration.environment();

/**
 * Sets the favicon depending of the current environment
 */
export const setFavicon = () => {
    const faviconImage: string = environment === "production" ? "favicon.ico" : "favicon-stage.ico";
    let headTitle = document.querySelector("head");
    let setFavicon = document.createElement("link");
    setFavicon.setAttribute("rel", "shortcut icon");
    setFavicon.setAttribute("href", `${window.location.origin}/${faviconImage}`);
    headTitle.appendChild(setFavicon);
};
