import React from "react";
import {CancellationSchedule} from "../views/cancellation-schedule/CancellationSchedule";
import {CancellationWorkflow} from "../views/cancellation-workflow/CancellationWorkflow";
import {CancellationEmail} from "../views/cancellation-email/CancellationEmail";
import {AddNights} from "../views/add-nights/AddNights";
import {MoveNights} from "../views/move-nights/MoveNights";
import {RemoveNights} from "../views/remove-nights/RemoveNights";
import {AddOns} from "../views/add-ons/AddOns";
import {AdjustRentView} from "../views/adjust-rent/AdjustRent";
import {AdjustFees} from "../views/adjust-fees/AdjustFees";
import {AdjustTaxesView} from "../views/adjust-taxes/AdjustTaxes";
import {ErrorComponent} from "../components/shared/error-component/ErrorComponent";
import {ERROR_MESSAGES} from "../constants/constants";
import {ResetFinancesView} from "../views/reset-finances/ResetFinances";
import {AppeasementsView} from "../views/appeasements/Appeasements";

export const APP_ROUTES = [
  {path: "/", index: true, element: <ErrorComponent message={ERROR_MESSAGES.NO_ROUTES} code={404} />},
  {path: "*", element: <ErrorComponent message={ERROR_MESSAGES.NO_ROUTES} code={404} />},
  {path: "/reservation/:id/cancellation-schedule", element: <CancellationSchedule />},
  {path: "/reservation/:id/cancellation-workflow", element: <CancellationWorkflow />},
  {path: "/reservation/:id/cancellation-email", element: <CancellationEmail />},
  {path: "/reservation/:id/add-nights", element: <AddNights />},
  {path: "/reservation/:id/move-nights", element: <MoveNights />},
  {path: "/reservation/:id/remove-nights", element: <RemoveNights />},
  {path: "/reservation/:id/add-ons", element: <AddOns />},
  {path: "/reservation/:id/adjust-rent", element: <AdjustRentView />},
  {path: "/reservation/:id/adjust-fees", element: <AdjustFees />},
  {path: "/reservation/:id/adjust-taxes", element: <AdjustTaxesView />},
  {path: "/reservation/:id/reset-finances", element: <ResetFinancesView />},
  {path: "/reservation/:id/appeasements", element: <AppeasementsView />},
];
