import React from "react";
import {Button} from "@vacasa/react-components-lib";
import localStyles from "./AdjustPreview.module.scss";
import {AdjustContainerLoader} from "../content-loaders/ContentLoaders";
import {useSelector} from "react-redux";
import {
  persistPreview,
  resetFinancesPreview,
  selectIsLoadingPreview,
  selectIsSaved,
  selectIsSaving,
  selectOriginalFinances,
  selectPreviewFinances,
} from "../../../store/reservation-adjustments/reservationAdjustmentsSlice";
import {useAppDispatch} from "../../../store/store";
import cx from "classnames";
import {getFinanceDataToDisplay} from "../../../utils/finance/Finance";
import {LegacyUrlGenerator} from "@common/utils";
import {useParams} from "react-router-dom";
import {redirectToURL} from "../../../utils/redirect-url/RedirectUrl";
import {message} from "antd";

export const AdjustPreview: React.FunctionComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {id: reservationId} = useParams<{id: string}>();
  const isLoadingPreview = useSelector(selectIsLoadingPreview);
  const isSaving = useSelector(selectIsSaving);
  const isSaved = useSelector(selectIsSaved);
  const originalFinances = useSelector(selectOriginalFinances);
  const previewFinances = useSelector(selectPreviewFinances);
  const financeDataToDisplay = getFinanceDataToDisplay(originalFinances, previewFinances);

  const saveAdjustment = async () => {
    const response = await dispatch(persistPreview("adjust_rent"));
    if (response?.meta?.requestStatus === "rejected") return message.error("An error ocurred while saving the changes, please try again!", 5);

    message.success("Changes were saved successfully. You will be redirect to Admin Finances in 5 seconds!", 5);
    redirectToURL(LegacyUrlGenerator.toReservationFinance(reservationId), 5);
  };

  const backToPreviousStep = () => {
    dispatch(resetFinancesPreview());
  };

  return (
    <>
      <div className={localStyles.tableContainer}>
        {isLoadingPreview ? (
          <AdjustContainerLoader />
        ) : (
          <table className={cx(localStyles.adjustTable, "fade-in")}>
            <thead>
              <tr>
                <th className={localStyles.tableHeaderCell}>Bucket</th>
                <th className={localStyles.tableHeaderCell}>Before</th>
                <th className={localStyles.tableHeaderCell}>After</th>
                <th className={localStyles.tableHeaderCell}>Difference</th>
              </tr>
            </thead>
            <tbody className={localStyles.tableBody}>
              {financeDataToDisplay?.map((item, index) => (
                <tr className={index === financeDataToDisplay?.length - 1 ? localStyles.highlightedTr : ""} key={index}>
                  <td className={localStyles.tableDataCell}>{item.name}</td>
                  <td className={localStyles.tableDataCell}>{item.before}</td>
                  <td className={localStyles.tableDataCell}>{item.after}</td>
                  <td className={localStyles.tableDataCell}>
                    {item.difference}
                    <span
                      className={parseFloat(item.difference) > 0 ? localStyles.positive : parseFloat(item.difference) < 0 ? localStyles.negative : ""}
                    >
                      {parseFloat(item.difference) > 0 ? "(+)" : parseFloat(item.difference) < 0 ? "(-)" : ""}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {!isLoadingPreview && (
          <div className={localStyles.buttonContainer}>
            <div className={localStyles.messageContainer}>
              {isSaving && (
                <>
                  <span className={localStyles.persistingMessage}>Saving the changes...please wait</span>
                  <span className="loader"></span>
                </>
              )}
            </div>
            <div className={localStyles.buttons}>
              <Button customClass="back-button" onClick={backToPreviousStep} disabled={isSaving || isSaved}>
                Back
              </Button>
              <Button customClass="save-button" onClick={saveAdjustment} disabled={isSaving || isSaved}>
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
