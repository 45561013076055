import {Button, Icon, Modal} from "@vacasa/react-components-lib";
import React from "react";
import {useSelector} from "react-redux";
import {selectHasReservationError, selectReservationErrorMessage} from "../../../store/reservation/reservationSlice";
import localStyles from "./Modals.module.scss";
import {selectAdjustmentErrorMessage, selectHasReservationAdjustmentError} from "../../../store/reservation-adjustments/reservationAdjustmentsSlice";

export const handleClickReload = () => {
  sessionStorage.clear();
  window.location.reload();
};

export const ReservationErrorModal = () => {
  const hasReservationError = useSelector(selectHasReservationError);
  const hasReservationAdjustmentError = useSelector(selectHasReservationAdjustmentError);
  const reservationErrorMessage = useSelector(selectReservationErrorMessage);
  const reservationAdjustmentErrorMessage = useSelector(selectAdjustmentErrorMessage);

  return (
    <Modal showModal={hasReservationError || hasReservationAdjustmentError} size="medium" setShowModal={() => {}} canExit={false}>
      <div className="flex-column">
        <span className={localStyles.trashIconError}>
          <Icon.AlertTriangle height={45} width={45} />
        </span>
        <p className={localStyles.reservationError}>{reservationErrorMessage === "" ? reservationAdjustmentErrorMessage : reservationErrorMessage}</p>
      </div>
      <div className={localStyles.reloadButtonContainer}>
        <Button variant="secondary" onClick={handleClickReload}>
          Reload
        </Button>
      </div>
    </Modal>
  );
};
