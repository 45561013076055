import React from "react";

type DropdownOption = string | {label: string; value: string};

type DropdownProps = {
  label: string;
  options: DropdownOption[];
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
};

export const useDropdown = ({label, options, placeholder, value, onChange}: DropdownProps) => {
  const dropdown = (
    <div className="select-container">
      <label className="select-label">{label}</label>
      <select value={value} onChange={(event) => onChange(event)} placeholder={placeholder ?? ""} className="select-options">
        <option disabled value={"0"}>
          {placeholder ?? "Select an option"}
        </option>
        {options?.map((option, index) => (
          <option key={index} value={typeof option === "string" ? option : option.value}>
            {typeof option === "string" ? option : option.label}
          </option>
        ))}
      </select>
    </div>
  );

  return {value, dropdown};
};
