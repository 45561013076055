import React, {useState} from "react";
import localStyles from "./FinanceLineItem.module.scss";
import {useSelector} from "react-redux";
import {selectUnitData, selectUnitFees} from "../../../store/unit/unitSlice";
import {validateInputFormatAmounts} from "../../../utils/general-utils/GeneralUtils";

type Props = {
  title: string;
  rows?: any[];
  value?: string;
  displayTotal?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, item?: any) => void;
};

export const FinanceLineItem: React.FunctionComponent<Props> = ({title, rows, value, displayTotal, onChange}): JSX.Element => {
  const [inputValues, setInputValues] = useState<string[]>(rows ? rows.map((row) => row.amount) : []);
  const unitFees = useSelector(selectUnitFees);
  const unitData = useSelector(selectUnitData);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, item?: any) => {
    const value = event?.target.value;
    const inputsValidated = validateInputFormatAmounts(value);

    if (inputsValidated) {
      setInputValues((prevInputValues) => {
        const updatedValues = [...prevInputValues];
        updatedValues[index] = value;
        return updatedValues;
      });
      if (onChange) onChange(event, item);
    }
  };

  const handleSingleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputsValidated = validateInputFormatAmounts(event.target.value);
    if (inputsValidated && onChange) {
      onChange(event);
    }
  };

  return (
    <>
      <span className={localStyles.title}>{title}</span>
      {rows ? (
        rows?.map((item, index) => (
          <React.Fragment key={index}>
            <div className={localStyles.lineItemsContainer}>
              <span className={localStyles.lineItemTitle}>{unitFees?.filter((fee) => fee.id === item?.id)[0]?.attributes?.name}</span>
              <input
                className={localStyles.lineItemValue}
                type="text"
                value={item.amount || ""}
                placeholder={`0.00 ${unitData?.currency?.code ?? "USD"}`}
                onChange={(event) => handleInputChange(event, index, item)}
              />
            </div>
          </React.Fragment>
        ))
      ) : (
        <div className={localStyles.lineItemsContainer}>
          <span className={localStyles.lineItemTitle}>{title} Total</span>
          <input
            className={localStyles.lineItemValue}
            type="text"
            value={value || ""}
            placeholder={`0.00 ${unitData?.currency?.code ?? "USD"}`}
            onChange={(event) => handleSingleInputChange(event)}
          />
        </div>
      )}
      {displayTotal && (
        <div className={localStyles.totalsSection}>
          <span className={localStyles.lineItemTotalTitle}>{title} Total</span>
          <span className={localStyles.lineItemTotalTitle}>
            ${inputValues.reduce((total, current) => total + parseFloat(current === "" ? "0" : current), 0).toFixed(2)}
          </span>
        </div>
      )}
    </>
  );
};
