import React, {useEffect, useState} from "react";
import localStyles from "./ResetFinances.module.scss";
import {FinanceLineItem} from "../shared/finance-line-item/FinanceLineItem";
import {useDropdown} from "../../hooks/useDropdown";
import {Button} from "@vacasa/react-components-lib";
import {useSelector} from "react-redux";
import {
  resetLineItems,
  selectOriginalFees,
  selectOriginalRent,
  selectOriginalTaxes,
  selectOriginalTotalRent,
  selectOriginalTotalTaxes,
  selectOverrideReason,
  selectOverrideReasons,
  selectReservationFees,
  selectReservationFinance,
  selectReservationId,
  selectReservationRent,
  selectReservationTaxes,
  selectTotalFees,
  selectTotalRent,
  selectTotalTaxes,
  setNewFees,
  setNewRent,
  setNewTaxes,
  setOverrideReason,
  setTotalRent,
  setTotalTaxes,
} from "../../store/reservation/reservationSlice";
import {filterOverrideReasons, formatOverrideReasons, orderToOriginal, orderToOriginalRent} from "../../utils/general-utils/GeneralUtils";
import {useAppDispatch} from "../../store/store";
import {createPreview, selectIsLoadingPreview} from "../../store/reservation-adjustments/reservationAdjustmentsSlice";
import {FinanceLineItemBreakdown} from "../shared/finance-line-item-breakdown/FinanceLineItemBreakdown";
import message from "antd/lib/message";
import Alert from "antd/lib/alert";
import {PreviewLoader} from "../shared/content-loaders/ContentLoaders";
import {selectConnectUnitData, selectUnitData, selectUnitFees} from "../../store/unit/unitSlice";
import {Fee, Rent, Tax} from "@common/typing";
import {zeroFillFeeAmounts, divideTotalEvenly, legacyDivideRent, legacyDivideTaxes} from "../../utils/finance/Finance";
import {FINANCE_LIMIT_SUM} from "../../constants/constants";
import {LegacyUrlGenerator} from "@common/utils";

export const ResetFinances = React.memo(() => {
  const dispatch = useAppDispatch();
  const [totalMaxError, setTotalMaxError] = useState<boolean>(false);
  const overrideReasons = useSelector(selectOverrideReasons);
  const reservationId = useSelector(selectReservationId);
  const reservationFinance = useSelector(selectReservationFinance);
  const totalTaxes = useSelector(selectTotalTaxes);
  const totalFees = useSelector(selectTotalFees);
  const totalRent = useSelector(selectTotalRent);
  const reservationRent = useSelector(selectReservationRent);
  const reservationOriginalTotalRent = useSelector(selectOriginalTotalRent);
  const reservationOriginalRent = useSelector(selectOriginalRent);
  const reservationOriginalTotalTaxes = useSelector(selectOriginalTotalTaxes);
  const reservationOriginalTaxes = useSelector(selectOriginalTaxes);
  const reservationOriginalFees = useSelector(selectOriginalFees);
  const reservationFees = useSelector(selectReservationFees);
  const reservationTaxes = useSelector(selectReservationTaxes);
  const overrideReasonsFiltered = filterOverrideReasons(overrideReasons);
  const overrideReasonsFormatted = formatOverrideReasons(overrideReasonsFiltered);
  const isLoadingPreview = useSelector(selectIsLoadingPreview);
  const unitFees = useSelector(selectUnitFees);
  const unitData = useSelector(selectUnitData);
  const connectUnitData = useSelector(selectConnectUnitData);
  const minRate = connectUnitData?.attributes?.min_rate;
  const overrideReason = useSelector(selectOverrideReason);

  const handleRentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTotalRent = event.target.value;
    const newRentValues =
      parseFloat(reservationOriginalTotalRent) === 0
        ? divideTotalEvenly(2, reservationRent.length, parseFloat(newTotalRent))
        : legacyDivideRent(reservationOriginalTotalRent, reservationOriginalRent, newTotalRent);
    const reservationRentUpdated = reservationRent.map((rent: Rent, index: number) => ({
      ...rent,
      rent: newRentValues[index].toString(),
    }));
    dispatch(setNewRent(reservationRentUpdated));
    dispatch(setTotalRent(newTotalRent));
  };

  const handleFeesChange = (event: React.ChangeEvent<HTMLInputElement>, item: Fee) => {
    const newFeeAmount = event.target.value;
    const feeUpdated = {
      id: item.id,
      amount: newFeeAmount,
    };
    dispatch(setNewFees(feeUpdated));
  };

  const handleTaxesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTotalTaxes = event.target.value;
    const newTaxesValues =
      parseFloat(reservationOriginalTotalTaxes) === 0
        ? divideTotalEvenly(2, reservationTaxes.length, parseFloat(newTotalTaxes))
        : legacyDivideTaxes(reservationOriginalTotalTaxes, reservationOriginalTaxes, newTotalTaxes);
    const reservationTaxesUpdated = reservationTaxes.map((tax: Tax, index: number) => ({
      ...tax,
      amount: newTaxesValues[index].toString(),
    }));
    dispatch(setNewTaxes(reservationTaxesUpdated));
    dispatch(setTotalTaxes(newTotalTaxes));
  };

  const handleClickReset = () => {
    message.success("Changes discarded!", 5);
    window.scrollTo(0, 0);
    setTotalMaxError(false);
    dispatch(resetLineItems());
  };

  const handleChangeReason = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setOverrideReason(event.target.value));
  };

  const {value, dropdown} = useDropdown({
    label: "Select Reason",
    options: overrideReasonsFormatted,
    placeholder: "Select a reason",
    value: overrideReason,
    onChange: handleChangeReason,
  });

  const handleClickPreview = async () => {
    window.scrollTo(0, 0);
    if (!value || value === "0") return;
    if (parseFloat(totalFees) + parseFloat(totalRent) + parseFloat(totalTaxes) >= 1000000) {
      setTotalMaxError(true);
      return;
    }
    await dispatch(
      createPreview({
        reservationId,
        adjustmentType: "reset_finances",
        lineItems: {
          rent: orderToOriginalRent(reservationRent),
          fees: orderToOriginal(reservationOriginalFees, zeroFillFeeAmounts(reservationFees)),
          taxes: orderToOriginal(reservationOriginalTaxes, reservationTaxes),
        },
        overrideNote: "",
        overrideReason: value,
        bookedCurrency: reservationFinance?.attributes?.booked_currency ?? "USD",
      })
    );
    setTotalMaxError(false);
  };

  useEffect(() => {
    dispatch(setOverrideReason(value));
  }, [value, dispatch]);

  return (
    <>
      <div className={localStyles.resetFinancePanel}>
        {reservationFinance && unitFees && !isLoadingPreview ? (
          <>
            <div>
              {minRate !== null && minRate > 0 ? (
                <Alert
                  className={localStyles.alertMessage}
                  description={
                    <span>
                      The minimum rate per night is {minRate} {unitData?.currency?.code ?? "USD"}. For extended stays (ESP) please verify any
                      additional min rate requirements in{" "}
                      <a href={LegacyUrlGenerator.toAdminUnitRatesAndRules(unitData?.legacy_unit_id)} target="_blank" rel="noopener noreferrer">
                        Rates & Rules
                      </a>{" "}
                      of Edit Units pages.
                    </span>
                  }
                  type="info"
                  showIcon
                />
              ) : null}
              {totalMaxError && (
                <Alert
                  className={localStyles.alertMessage}
                  message={`The total sum of rent + taxes + fees cannot be more than $${FINANCE_LIMIT_SUM}`}
                  type="error"
                  showIcon
                />
              )}
              <FinanceLineItemBreakdown title="Rent" value={totalRent} onChange={handleRentChange} />
              <hr className={localStyles.hrDivider} />

              <FinanceLineItem title="Fees" displayTotal value={totalFees} rows={reservationFees} onChange={handleFeesChange} />
              <hr className={localStyles.hrDivider} />

              <FinanceLineItem title="Taxes" value={totalTaxes} onChange={handleTaxesChange} />
              <hr className={localStyles.hrDivider} />
            </div>
            <>
              <span className={localStyles.reason}>Reason for Reset Finances</span>
              {dropdown}
            </>
          </>
        ) : (
          <PreviewLoader />
        )}
      </div>
      <div className={localStyles.buttonsSection}>
        <Button customClass={localStyles.undoChangesButton} variant="info" onClick={handleClickReset} disabled={isLoadingPreview}>
          Undo Changes
        </Button>
        <Button
          customClass={localStyles.submitButton}
          variant="secondary"
          disabled={!value || parseInt(value) === 0 || isLoadingPreview}
          onClick={handleClickPreview}
        >
          Preview
        </Button>
      </div>
    </>
  );
});
