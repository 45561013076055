export const AppeasementScenarios = [
    {
        responsibleParty: {
            name: "Vacasa",
            key: "vacasa",
        },
        possibleReasons: [
            {
                name: "Dinner Comp",
                key: "dinner_comp",
            },
            {
                name: "Discount Added",
                key: "discount_added",
            },
            {
                name: "Loss of Amenity",
                key: "lost_amenity",
            },
            {
                name: "Loss of Amenity Hot tub",
                key: "lost_amenity_hot_tub",
            },
            {
                name: "Missed Clean",
                key: "missed_clean",
            },
            {
                name: "Subpar Clean",
                key: "subpar_clean",
            },
        ],
    },
    {
        responsibleParty: {
            name: "Owner",
            key: "owner",
        },
        possibleReasons: [
            {
                name: "Discount Added",
                key: "discount_added",
            },
            {
                name: "Loss of Amenity",
                key: "lost_amenity",
            },
            {
                name: "Missed Clean",
                key: "missed_clean",
            },
            {
                name: "Subpar Clean",
                key: "subpar_clean",
            },
        ],
    },
];
