import React from "react";
import localStyles from "./AdjustContainer.module.scss";
import {Header} from "../header/Header";
import {Button} from "@vacasa/react-components-lib";
import {AdjustPreview} from "../adjust-preview/AdjustPreview";
import {ROUTE_SUFFIX} from "../../../constants/constants";
import {useSelector} from "react-redux";
import {selectUnitData} from "../../../store/unit/unitSlice";
import {selectIsFetching} from "../../../store/reservation/reservationSlice";
import {AdjustContainerLoader} from "../content-loaders/ContentLoaders";
import {
  selectAdjustmentErrorMessage,
  selectHasReservationAdjustmentError,
  selectIsLoadingPreview,
  selectPreviewFinances,
} from "../../../store/reservation-adjustments/reservationAdjustmentsSlice";
import cx from "classnames";
import {Alert} from "antd";
import {ReservationErrorModal} from "../modals/Modals";

type Props = {
  title: string;
  rows?: JSX.Element;
  columns?: JSX.Element;
  generatePreview?: () => void;
};

export const AdjustContainer: React.FunctionComponent<Props> = ({title, rows, columns, generatePreview}) => {
  const currentUrl = window.location.href;
  const displayRentAlert = currentUrl.includes(ROUTE_SUFFIX.ADJUST_RENT);
  const unitData = useSelector(selectUnitData);
  const isFetching = useSelector(selectIsFetching);
  const isLoadingPreview = useSelector(selectIsLoadingPreview);
  const previewFinances = useSelector(selectPreviewFinances);
  const hasReservationAdjustmentError = useSelector(selectHasReservationAdjustmentError);
  const adjustmentErrorMessage = useSelector(selectAdjustmentErrorMessage);

  /* This component is to display the unit min_rate alert */
  const MinRateAlert = () => (
    <div className={localStyles.alertContainer}>
      <Alert
        className={localStyles.alertMessage}
        message={`The minimum rate per night is ${unitData?.min_rate} ${unitData?.currency?.code ?? "USD"}.`}
        type="info"
        showIcon
      />
    </div>
  );

  return (
    <>
      {/* Display the Header component */}
      <Header title={title} />
      {
        /* if the data is being fetched a content loader is rendered */
        isFetching || isLoadingPreview || !unitData ? (
          <div className={localStyles.tableContainer}>
            <AdjustContainerLoader />
          </div>
        ) : previewFinances ? (
          <AdjustPreview />
        ) : (
          <>
            {
              /* This validation is to check if the <MinRateAlert /> component is rendered */
              displayRentAlert && unitData && !isLoadingPreview && unitData?.min_rate > 0 && <MinRateAlert />
            }
            {
              /* If we get an error generating the preview or persisting the preview we display an alert */
              hasReservationAdjustmentError && <Alert className={localStyles.alertMessage} message={adjustmentErrorMessage} type="error" showIcon />
            }
            <div className={localStyles.tableContainer}>
              <table data-testid="adjust-container" className={cx(localStyles.adjustTable, "fade-in")}>
                <thead>
                  <tr>{columns}</tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
              <div className={localStyles.previewButtonContainer}>
                <Button customClass="preview-button" onClick={generatePreview}>
                  Preview
                </Button>
              </div>
            </div>
            {/* If there is an error calling some services we display a modal with the details error */ <ReservationErrorModal />}
          </>
        )
      }
    </>
  );
};
