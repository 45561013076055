import React from "react";
import {BsArrowLeftShort} from "react-icons/bs";
import styles from "./BackButton.module.scss";
import {useParams} from "react-router-dom";
import {redirectToURL} from "../../../utils/redirect-url/RedirectUrl";
import {LegacyUrlGenerator} from "@common/utils";
import {useAppDispatch} from "../../../store/store";
import {useSelector} from "react-redux";
import {resetFinancesPreview, selectAdjustmentPreviewId} from "../../../store/reservation-adjustments/reservationAdjustmentsSlice";

type Props = {
  text: string;
};

export const BackButton: React.FunctionComponent<Props> = ({text}): JSX.Element => {
  const dispatch = useAppDispatch();
  const adjustmentPreviewId = useSelector(selectAdjustmentPreviewId);
  const {id: reservationId} = useParams<{id: string}>();

  return (
    <span
      className={styles.backElement}
      onClick={() =>
        !!adjustmentPreviewId ? dispatch(resetFinancesPreview()) : redirectToURL(LegacyUrlGenerator.toReservationFinance(reservationId))
      }
    >
      <BsArrowLeftShort className={styles.arrow} size={25} />
      {text}
    </span>
  );
};
