import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ResManService} from "@common/services";
import {RootState} from "../store";
import {CHANNEL_UNIT_ID_TYPE, VACASA_SOURCE_NAME} from "../../constants/constants";

// Service singletons
const resmanService = ResManService.getInstance();

export const postQuotesSeed = createAsyncThunk("quotes/postQuotesSeed", async (data: any): Promise<any> => {
    const {unitId, checkin, checkout, adults, children, dogs, currencyCode} = data;
    const queryParams = {
        data: {
            attributes: {
                quotes: [
                    {
                        unit: {
                            id_type: CHANNEL_UNIT_ID_TYPE,
                            id: unitId.toString(),
                        },
                        origin: {
                            source_name: VACASA_SOURCE_NAME,
                        },
                        stay: {
                            check_in: checkin,
                            check_out: checkout,
                            adults,
                            children,
                            dogs,
                        },
                        finances: {
                            booking_currency_code: currencyCode,
                        },
                        add_ons: [],
                        ignore_rules: [],
                    },
                ],
            },
        },
    };

    try {
        return await resmanService.postQuotesSeed(queryParams);
    } catch (error) {
        throw new Error(error?.message);
    }
});

export interface quotesState {
    isFetching: boolean;
    data: any;
}

const initialState: quotesState = {
    isFetching: false,
    data: null,
};

const quotesSlice = createSlice({
    name: "quotes",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postQuotesSeed.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(postQuotesSeed.fulfilled, (state, {payload}) => {
                state.isFetching = false;
                state.data = payload;
            });
    },
});

export const selectQuotesTaxes = (state: RootState) => state.quotes?.data?.attributes?.quotes[0]?.finances?.line_items?.taxes;

export default quotesSlice.reducer;
