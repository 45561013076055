import {Fee, OriginalFinanceItem, PreviewFinanceItem, Rent, Tax} from "@common/typing";

// Used for sum the total fees or taxes
export function sumFinances(finances: (Tax | Fee)[]): string {
    return finances?.reduce((total, financeItem) => total + parseFloat(financeItem.amount), 0).toFixed(2);
}

// Sum the total rent
export function sumRentFinances(finances: Rent[]): string {
    return finances?.reduce((total, financeItem) => total + parseFloat(financeItem.rent), 0).toFixed(2);
}

// Rest the total rent
export function subtractTotalFinances(totalOriginal: string, totalPreview: string): string {
    return (parseFloat(totalPreview) - parseFloat(totalOriginal)).toFixed(2);
}

export function getFinanceDataToDisplay(originalFinances: OriginalFinanceItem, previewFinances: PreviewFinanceItem) {
    const currencyCode = originalFinances?.booked_currency;
    const totalOriginalFees = sumFinances(originalFinances?.fees);
    const totalOriginalTaxes = sumFinances(originalFinances?.taxes);
    const totalOriginalRent = sumRentFinances(originalFinances?.rent);
    const totalPreviewFees = sumFinances(previewFinances?.fees);
    const totalPreviewTaxes = sumFinances(previewFinances?.taxes);
    const totalPreviewRent = sumRentFinances(previewFinances?.rent);

    return [
        {
            name: "Rent",
            before: `${totalOriginalRent} ${currencyCode}`,
            after: `${totalPreviewRent} ${currencyCode}`,
            difference: `${subtractTotalFinances(totalOriginalRent, totalPreviewRent)} ${currencyCode}`,
        },
        {
            name: "Fees",
            before: `${totalOriginalFees} ${currencyCode}`,
            after: `${totalPreviewFees} ${currencyCode}`,
            difference: `${subtractTotalFinances(totalOriginalFees, totalPreviewFees)} ${currencyCode}`,
        },
        {
            name: "Taxes",
            before: `${totalOriginalTaxes} ${currencyCode}`,
            after: `${totalPreviewTaxes} ${currencyCode}`,
            difference: `${subtractTotalFinances(totalOriginalTaxes, totalPreviewTaxes)} ${currencyCode}`,
        },
        {
            name: "Total",
            before: `${originalFinances?.total} ${currencyCode}`,
            after: `${previewFinances?.total} ${currencyCode}`,
            difference: `${subtractTotalFinances(originalFinances?.total, previewFinances?.total)} ${currencyCode}`,
        },
    ];
}

export function getRentLineItemsFormatted(reservationRent: Rent[], inputValues: string[]) {
    const rentLineItemsUpdated = reservationRent?.map((rent, index) => ({
        ...rent,
        rent: inputValues[index],
    }));

    // Filter the rent array to get just the rents changed
    return rentLineItemsUpdated?.filter((rentItem) => rentItem.rent);
}

/**
 * Distributes an amount evenly among a specified number of shares.
 *
 * @param {number} precision - The number of decimal places to round the result to.
 * @param {number} divider - The number of shares to distribute the amount to.
 * @param {number} numerator - The total amount to distribute.
 * @returns {Array<number>} - An array containing the distributed values.
 */
export const divideTotalEvenly = (precision: number, divider: number, numerator: number): number[] => {
    const arr = [];
    while (divider > 0) {
        let amount = Math.round((numerator / divider) * Math.pow(10, precision)) / Math.pow(10, precision);
        arr.push(amount);
        numerator -= amount;
        divider--;
    }
    return arr.sort((a, b) => b - a);
};

/**
 * This function replicates the legacy calculation of the rent
 * @param {string} oldTotalRent - The total rent of the old "folio" (original rent)
 * @param {Rent[]} oldRent - The rent of the old "folio" (original rent)
 * @param {string} newTotalRent - The new total rent entered by the user
 * @returns {Array<string>} - An array containing the new rent values.
 */
export const legacyDivideRent = (oldTotalRent: string, oldRent: Rent[], newTotalRent: string): string[] => {
    // Push to an array the old rent / old total rent
    const oldRentDividedByOldTotalRent = oldRent.map((rent: Rent) => (newTotalRent === "" ? "" : parseFloat(rent.rent) / parseFloat(oldTotalRent)));

    // With these new values each new rent is multiplied by the new total rent.
    const newRent = oldRentDividedByOldTotalRent.map((oldRent: number) => Math.round(oldRent * parseFloat(newTotalRent) * 100) / 100);

    //Then all new values are added with and the new total is subtracted.
    const totalRent = newRent.reduce((total, current) => current + total, 0);
    const totalDifference = totalRent - parseFloat(newTotalRent);

    //This difference is subtracted to the last new rent
    const lastRentMinusDifference = newRent[newRent.length - 1] - totalDifference;

    //Replace the last rent with the value updated
    newRent[newRent.length - 1] = lastRentMinusDifference;

    return newRent.map((rent) => rent.toFixed(2));
};

/**
 * This function replicates the legacy calculation of the taxes
 * @param {string} oldTotalTaxes - The total taxes of the old "folio" (original taxes)
 * @param {Rent[]} oldTaxes - The taxes of the old "folio" (original taxes)
 * @param {string} newTotalTaxes - The new total taxes entered by the user
 * @returns {Array<string>} - An array containing the new taxes values.
 */
export const legacyDivideTaxes = (oldTotalTaxes: string, oldTaxes: Tax[], newTotalTaxes: string): any => {
    const currentTaxPercentages = oldTaxes.map((tax) => (parseFloat(oldTotalTaxes) === 0 ? 0 : parseFloat(tax.amount) / parseFloat(oldTotalTaxes)));

    // With these new values each new tax is multiplied by the new total taxes.
    let newTaxes: number[] = currentTaxPercentages.map((taxPercentage: number) => {
        return Math.round(taxPercentage * parseFloat(newTotalTaxes) * 100) / 100;
    });

    //Then all new values are added with and the new total is subtracted.
    const totalTaxes = newTaxes.reduce((total, current) => current + total, 0);
    const totalDifference = totalTaxes - parseFloat(newTotalTaxes);

    //This difference is subtracted to the last new tax
    const lastTaxMinusDifference = newTaxes[newTaxes.length - 1] - totalDifference;

    //Replace the last tax with the value updated
    newTaxes[newTaxes.length - 1] = lastTaxMinusDifference;

    return newTaxes.map((tax) => tax.toFixed(2));
};

/**
 * Fill with "0" the empty amount of a fee
 * @param fees an array of reservation fees
 * @returns Fee []
 */
export const zeroFillFeeAmounts = (fees: Fee[]) => {
    return fees?.map((fee) => ({
        ...fee,
        amount: fee.amount === "" ? "0" : fee.amount,
    }));
};
