import React from "react";
import localStyles from "./ErrorComponent.module.scss";

type ErrorProps = {
  message: string;
  code: number;
};

export const ErrorComponent: React.FC<ErrorProps> = ({message, code}) => {
  return (
    <div className={localStyles.container}>
      <div className={localStyles.errorComponentBox}>
        <h2>Error {code}</h2>
        <p>{message}</p>
        <p>If you received an error regarding ROUTES, please verify if you are in the correct route, otherwise ignore this message.</p>
      </div>
    </div>
  );
};
