import React from "react";
import ContentLoader from "react-content-loader";

export const ReservationDetailsLoader: React.FunctionComponent = () => {
  const rows = [...Array(3).keys()];
  return (
    <ContentLoader viewBox="0 0 950 80" height={80} width={950}>
      {rows.map((row) => (
        <React.Fragment key={row}>
          <rect x="0" y={8 + row * 30} rx="6" ry="6" width={60} height="12" />
          <rect x="80" y={8 + row * 30} rx="6" ry="6" width={150} height="12" />

          <rect x="500" y={8 + row * 30} rx="6" ry="6" width={60} height="12" />
          <rect x="580" y={8 + row * 30} rx="6" ry="6" width={150} height="12" />
        </React.Fragment>
      ))}
    </ContentLoader>
  );
};

export const AdjustContainerLoader: React.FunctionComponent = () => {
  const rows = [...Array(5).keys()];

  return (
    <ContentLoader viewBox="0 0 970 398" height={398} width={970}>
      {rows.map((row) => (
        <React.Fragment key={row}>
          <rect x="0" y={6 * row * 10} rx="6" ry="6" width={950} height="25" />
        </React.Fragment>
      ))}
      <rect x="820" y="330" rx="6" ry="6" width={130} height="33" />
    </ContentLoader>
  );
};

export const HeaderLightItemLoader: React.FunctionComponent = () => {
  const rows = [...Array(5).keys()];

  return (
    <ContentLoader viewBox="0 0 500 120" height={120} width={500}>
      {rows.map((row) => (
        <React.Fragment key={row}>
          <rect x="0" y={8 + row * 25} rx="6" ry="6" width={85} height="12" />
          <rect x="120" y={8 + row * 25} rx="6" ry="6" width={150} height="12" />
        </React.Fragment>
      ))}
    </ContentLoader>
  );
};

export const PreviewLoader: React.FunctionComponent = () => {
  const rows = [
    {x: 0, y: 0, rx: 6, ry: 6, width: 52, height: 25},
    {x: 0, y: 50, rx: 6, ry: 6, width: 70, height: 25},
    {x: 300, y: 50, rx: 6, ry: 6, width: 330, height: 40},
    {x: 360, y: 100, rx: 6, ry: 6, width: 260, height: 25},
    {x: 0, y: 180, rx: 6, ry: 6, width: 52, height: 25},
    {x: 0, y: 230, rx: 6, ry: 6, width: 70, height: 25},
    {x: 300, y: 230, rx: 6, ry: 6, width: 330, height: 40},
  ];

  return (
    <ContentLoader viewBox="0 0 900 400" height={400} width={900}>
      {rows.map((row, index) => (
        <React.Fragment key={index}>
          <rect x={row.x} y={row.y} rx={row.rx} ry={row.ry} width={row.width} height={row.height} />
        </React.Fragment>
      ))}
    </ContentLoader>
  );
};
