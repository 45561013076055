import React from "react";
import {AppeasementsContainer} from "../../components/appeasements/AppeasementsContainer";

export const AppeasementsView: React.FunctionComponent = () => {
  return (
    <div id="message-container">
      <AppeasementsContainer />
    </div>
  );
};
