/**
 * Redirect to URL specified. If a time is specified, it will redirect after that time. (is in seconds)
 * @param {string} url
 * @param {number} [timeToRedirect=0]
 */
export const redirectToURL = (url: string, timeToRedirect: number = 0): void => {
    setTimeout(() => {
        window.top.location.href = url;
    }, timeToRedirect * 1000);
};
